import "./index.css";

import React from "react";
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {VisitDataObject} from 'DataObjects/visit';
import DataValueValidation from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {getString, isset} from 'Core/helpers/data';
import * as actions from "./actions";
import {getGlobalActions} from 'Core/helpers/redux';
import DateInput from 'Core/components/input/DateInput';
import {getDate} from 'Core/helpers/datetime';
import TextareaInput from 'Core/components/input/TextareaInput';
import OfficeLocationSelectInput from 'Components/input/OfficeLocationSelectInput';
import TherapistSelectInput from 'Components/input/TherapistSelectInput';
import {loadPatientTherapyAction} from "Components/advanced/PatientRecord/components/Therapy/actions";
import {fetchPatientRecordAction, loadPatientRecordAction} from "Components/advanced/PatientRecord/actions";
import {calendar_min_time_hours, icon_font_error_symbol} from "Config/app";
import DayTimeInput from "Components/input/DayTimeInput";
import {isBefore} from "date-fns";
import PatientTherapySelectInput from "Components/input/PatientTherapySelectInput";
import Icon from "Core/components/display/Icon";
import Label from "Core/components/display/Label";
import {Link} from "react-router-dom";
import Spinner from "Core/components/display/Spinner";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({
	...actions, loadPatientTherapyAction, loadPatientRecordAction, fetchPatientRecordAction, 
});

class VisitDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: 
				props.isNew ? 
					new VisitDataObject(
						null,
						props.patientId,
						props.therapyId,
						new Date(), 
						getDate(new Date().setHours(calendar_min_time_hours, 0, 0, 0)),
						getDate(new Date().setHours(calendar_min_time_hours + 1, 0, 0, 0)),
					)
					: 
					null,
			/**
			 * Patient information for the current data.patineId
			 * @type {?PatientRecordDataObject|undefined}
			 */
			patientRecord: null,
		}, {
			translationPath: 'VisitDialog',
			enableLoadOnDataPropChange: true,
			disableLoad: true,
		});
		
		// Data loading methods
		this.loadPatientRecord = this.loadPatientRecord.bind(this);
		
	}

	/** @inheritDoc */
	async asyncComponentDidMount(override = false) {
		await super.asyncComponentDidMount(override);
		
		const {isNew, id, fetchVisitAction} = this.props;
		
		// Load data if on edit dialog and ID is specified
		if (!isNew && !!id) {
			await this.executeAbortableActionMount(fetchVisitAction, id)
				// Load visit into local component's state
				.then(data => this.setData(data).then(() => data))
				// Load patient info
				.then(() => this.loadPatientRecord(true));
		} else {
			// Load patient info
			this.loadPatientRecord(true).then();
		}
	}

	/**
	 * Load patient info into local state
	 * 
	 * @param {boolean} [onMount] - Flag that specifies if this method is called during the mount cycle.
	 * @return {Promise<Object>}
	 */
	loadPatientRecord(onMount) {
		const {fetchPatientRecordAction} = this.props;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);
		
		return this.setState({patientRecord: null})
			.then(() => executeAbortableAction(fetchPatientRecordAction, getString(this.getData(), 'patientId')))
			.then(patientRecord => this.setState({patientRecord}));
	}

	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		/** @type {VisitDataObject} */
		const dataToValidate = this.getData();
		
		dataValidation.addRule('date', 'required', 'date');
		dataValidation.addRule('timeFrom', 'required');
		dataValidation.addRule('timeTo', 'required');
		dataValidation.addRule('officeLocationId', 'required');

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	async save() {
		const {
			isNew, patientId, therapyId, dialogGUIID, createVisitAction, updateVisitAction, addSuccessMessageAction,
			loadPatientRecordAction, loadPatientTherapyAction
		} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const visit = (
				isNew ?
					await this.executeAbortableAction(createVisitAction, data) :
					await this.executeAbortableAction(updateVisitAction, data.id, data, false)
			);

			if (isset(visit)) {
				// Show success message
				addSuccessMessageAction(this.t('create_success_msg'));
				
				if (isNew) {
					// Reload patient record to update global statistics
					await this.executeAbortableAction(loadPatientRecordAction, patientId);
					// Reload patient therapy to update statistics
					if (!!therapyId && therapyId !== '*') {
						await this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section');
					}
				}

				this.close();
				
				// Trigger component's onSave event if visit was saved successfully
				executeComponentCallback(this.props.onSave, visit, dialogGUIID);
			}
		}
	}

	render() {
		const {isNew, patientId, therapyId} = this.props;

		/** @type {VisitDataObject} */
		const data = this.getData();
		/** @type {?PatientRecordDataObject|undefined} */
		const patientRecord = this.state.patientRecord;

		return this.renderDialog(
			this.renderTitle(isNew ? this.t(!!therapyId ? 'title_create_therapy' : 'title_create') : this.t('title')),
			(
				<FormWrapper className="dialog-form">
					{
						patientRecord === null ?
							<div className="notice patient-link-notice">
								<Spinner />
							</div>
						: !isset(patientRecord) ?
							<div 
								className="notice error bold patient-link-notice cursor-pointer" 
								onClick={() => this.loadPatientRecord()}
							>
								<Label icon={icon_font_error_symbol} content={this.t('patient_record_load_error_msg')} />
							</div>
						: !!patientRecord ?
							<Link
								className="notice bold patient-link-notice"
								to={
									// @note Link is hardcoded because importing router paths for it will result in some circular 
									// dependency issues
									`/medical-office/patient-record/item/${patientRecord.id}`
								}
							>
								<Icon key="icon" symbol="user"/>
								<Label
									key="label"
									element="span"
									content={
										`${patientRecord.firstName}${patientRecord.middleName ?
											` (${patientRecord.middleName})` :
											``
										} ` +
										`${patientRecord.lastName}`
									}
								/>
							</Link>
							: null
					}
					
					{!isNew && !!data?.therapyId && data?.therapyId !== '*' ?
						<FormField
							label={this.t('therapyIdLabel')}
							errorMessages={this.getValidationErrors('therapyId')}
						>
							<PatientTherapySelectInput
								isDisabled={true}
								className="patient-therapy-select-input separate-toolbar-right"
								patientId={patientId}
								value={data.therapyId}
							/>
						</FormField>
						: null
					}
					
					<FormField
						required={true}
						label={this.t('dateLabel')}
						errorMessages={this.getValidationErrors('date')}
					>
						<DateInput
							preventTab={true}
							value={data?.date || null}
							onChange={v => this.handleValueChange('date', v)}
						/>
					</FormField>

					<FormField
						required={true}
						label={this.t('timeFromLabel')}
						errorMessages={this.getValidationErrors('timeFrom')}
					>
						<DayTimeInput
							timeConstraints={{minutes: {step: 5}}}
							value={data?.timeFrom || null}
							onChange={v => {
								let previousTimeFrom = new Date(this.getValue('timeFrom'));
								let difference = new Date(v) - previousTimeFrom;
								this.handleValueChange('timeFrom', v)
									.then(() => {
										let newTimeTo = new Date(new Date(this.getValue('timeTo')).getTime() + difference);
										this.handleValueChange('timeTo', newTimeTo).then();
									})
							}}
						/>
					</FormField>

					<FormField
						required={true}
						label={this.t('timeToLabel')}
						errorMessages={this.getValidationErrors('timeTo')}
					>
						<DayTimeInput
							timeConstraints={{minutes: {step: 5}}}
							value={data?.timeTo || null}
							onChange={v => this.handleValueChange('timeTo', v)
								.then(() => {
									if (isBefore(this.getValue('timeTo'), this.getValue('timeFrom'))) {
										this.handleValueChange('timeTo', this.getValue('timeFrom')).then();
									}
								})
							}
						/>
					</FormField>

					<FormField
						required={true}
						label={this.t('officeLocationIdLabel')}
						errorMessages={this.getValidationErrors('officeLocationId')}
					>
						<OfficeLocationSelectInput
							isClearable={false}
							value={data?.officeLocationId || null}
							onChange={v => this.handleValueChange('officeLocationId', v)}
						/>
					</FormField>

					<FormField
						label={this.t('therapistIdLabel')}
						errorMessages={this.getValidationErrors('therapistId')}
					>
						<TherapistSelectInput
							isClearable={false}
							value={data?.therapistId || null}
							onChange={v => this.handleValueChange('therapistId', v)}
						/>
					</FormField>

					<FormField
						className="multiline-form-field"
						label={this.t('noteLabel')}
						errorMessages={this.getValidationErrors('note')}
					>
						<TextareaInput
							rows={3}
							name="note"
							value={data?.note || ''}
							onChange={this.handleInputChange}
						/>
					</FormField>
				</FormWrapper>
			),
			{
				createLabel: this.t('create_btn'),
				createIcon: 'check'
			}
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
VisitDialog.propTypes = {
	// Flag that specifies if this dialog is for creating a new item
	// @note This prop can be dynamically changed in the popup component.
	isNew: PropTypes.bool,
	// Visit ID used when "isNew" prop is false (edit visit)
	id: PropTypes.string,
	// ID of the patient
	patientId: PropTypes.string.isRequired,
	// ID of the therapy
	therapyId: PropTypes.string,

	// Event triggered if visit was saved successfully and dialog has already been closed
	// @param {VisitDataObject} visit, 
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
};

export default connect(null, allActions)(VisitDialog);