import styles from "./index.module.css";

import React from "react";
import DataComponent from 'Core/components/DataComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectors} from './reducer';
import {selectors as patientRecordSelectors} from '../../reducer';
import {getPageActions} from 'Core/helpers/redux'; 
import * as actions from './actions';
import {PAGINATION_TYPE} from 'Core/components/action/Pagination';
import DataTable, {DATA_TABLE_CELL_TYPE} from 'Core/components/advanced/DataTable';
import {areAllObjectPropsEmpty, getArray, getNumber, getString, isset, trimArray} from 'Core/helpers/data';
import {get} from 'lodash';
import * as filterDataMap from 'DataMap/appointment/filter';
import {scrollToSelector} from 'Core/helpers/dom';
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from 'Core/components/advanced/SimpleStaticSearch';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {STANDARD_DATE_TIME_FORMAT} from 'Core/const/datetime';
import {capitalize} from 'Core/helpers/string';
import {
	DataTableCellAnyTypeOptionsDataObject,
	DataTableCellDateTypeOptionsDataObject,
} from 'Core/components/advanced/DataTable/DataTableCell/dataObjects';
import {getAppLocaleDateFormat} from 'Core/helpers/locale';
import {LOCALE_DATE_FORMAT_NAME as LOCALE_DATE_FORMAT} from 'Core/const/locale';
import {
	PATIENT_RECORD_DISPLAY_TYPE,
	PATIENT_RECORD_DISPLAY_TYPES
} from 'Components/advanced/PatientRecord/const';
import {icon_font_create_symbol, icon_font_delete_symbol, icon_font_edit_symbol} from 'Config/app';
import Label from 'Core/components/display/Label';
import {Tooltip} from 'react-tippy';
import ConfirmDialog from 'Core/components/dialogs/ConfirmDialog';
import {isSuccessful} from 'Core/helpers/io';
import AppointmentDialog from "Components/dialogs/AppointmentDialog";
import * as updateAppointmentDataMap from "DataMap/appointment/update";
import {loadPatientTherapyAction} from "Components/advanced/PatientRecord/components/Therapy/actions";
import {loadPatientRecordAction} from "Components/advanced/PatientRecord/actions";
import AppointmentsMainTableToolbar from "./components/MainTableToolbar";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	patientId: get(patientRecordSelectors.getPatientRecord(state), 'id', null),
	therapyId: get(patientRecordSelectors.getPatientTherapy(state), 'id', null),

	mainList: selectors.getPatientRecordAppointmentsList(state),
	mainListPagination: selectors.getPatientRecordAppointmentsListPagination(state),
	mainListSort: selectors.getPatientRecordAppointmentsListSort(state),
	mainListFilter: selectors.getPatientRecordAppointmentsListFilter(state),
});

class Appointments extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,
			},

			/**
			 * Therapist codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapistCodebook: undefined,
			/**
			 * Office location codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			officeLocationCodebook: undefined,
		}, {
			translationPath: 'Appointments',
			disableLoad: true,
		});

		// Refs
		this.mainListFilterRef = null;
		this.mainListToolbarRef = null;
		this.mainListTableRef = null;

		// Data methods
		this.calculateTherapyId = this.calculateTherapyId.bind(this);
		this.loadPageCodebook = this.loadPageCodebook.bind(this);
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Dialog methods
		this.openItemDialog = this.openItemDialog.bind(this);

		// Action methods
		this.deleteMainListItem = this.deleteMainListItem.bind(this);

		// Event handling methods
		this.handleMultiDeleteMainListItems = this.handleMultiDeleteMainListItems.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}

	async asyncComponentDidMount(override = false) {
		await super.asyncComponentDidMount(override);

		const {active} = this.props;

		// Initial data load
		if (active) await this.filterMainList(null);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {resetPatientRecordAppointmentsListAction} = this.props;
		
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			// Reload data when activated, selected therapy or patient record display type changes 
			.then(() => {
				const {displayType} = this.props;
				const therapyId = this.calculateTherapyId();
				const prevTherapyId = this.calculateTherapyId(prevProps.therapyId);

				if (
					this.props.active && (
						this.props.active !== prevProps.active ||
						displayType !== prevProps.displayType ||
						therapyId !== prevTherapyId
					)
				) {
					// Clear main list selection
					if (this.mainListTableRef) this.mainListTableRef.clearSelection();
					
					// Clear the main list from Redux store if selected therapy is cleared
					if (
						displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
						therapyId !== prevTherapyId && !therapyId
					) {
						resetPatientRecordAppointmentsListAction();
						return Promise.resolve();
					}
					
					return this.loadMainListPage();
				}
				return Promise.resolve();
			});
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		const {resetPatientRecordAppointmentsListAction} = this.props;
		resetPatientRecordAppointmentsListAction();
	}

	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Calculate therapy type ID based on the display type
	 *
	 * @param {string} [therapyId] - Reference therapy type ID. If not specified, 'therapyId' from props will be used.
	 * @return {string|'*'|null}
	 */
	calculateTherapyId(therapyId) {
		const {displayType} = this.props;
		const id = (isset(therapyId) ? therapyId : this.props.therapyId);

		switch (displayType) {
			case PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY: return id;
			case PATIENT_RECORD_DISPLAY_TYPE.ALL: return '*';
			case PATIENT_RECORD_DISPLAY_TYPE.NO_THERAPY: return null;
			// no default
		}
	}

	/**
	 * Load a specific page codebook data into local state
	 * @note Page codebook actions are define in 'store/page/actions/codebooks' directory.
	 *
	 * @param {string} codebookName - Name of the codebook to load (for example: 'therapist').
	 * @param {any} [actionParams] - Any codebook action parameters (see the codebook definitions for reference).
	 * @return {Promise<CodebookItem[]>}
	 */
	loadPageCodebook(codebookName, ...actionParams) {
		const loadCodebookAction = get(this.props, `fetch${capitalize(codebookName)}CodebookAction`);

		if (loadCodebookAction) {
			this.setState({[`${codebookName}Codebook`]: null})
				.then(() => this.executeAbortableAction(loadCodebookAction, ...actionParams))
				.then(codebook => this.setState({[`${codebookName}Codebook`]: codebook}).then(() => codebook));
		}
		return Promise.resolve([]);
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {
			patientId, loadPatientRecordAppointmentsListAction, mainListPagination, mainListSort, mainListFilter
		} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);
		const therapyId = this.calculateTherapyId();

		return this.executeAbortableAction(
			loadPatientRecordAppointmentsListAction, patientId, therapyId, oFilter, pageNo, perPage, sortBy, sortDir
		)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {
			patientId, loadPatientRecordAppointmentsListAction, mainListPagination, mainListSort, mainListFilter
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);
		const therapyId = this.calculateTherapyId();

		return this.executeAbortableAction(
			loadPatientRecordAppointmentsListAction, patientId, therapyId, oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {patientId, loadPatientRecordAppointmentsListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);
		const therapyId = this.calculateTherapyId();

		return this.executeAbortableAction(
			loadPatientRecordAppointmentsListAction, patientId, therapyId, oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			patientId, loadPatientRecordAppointmentsListAction, setPatientRecordAppointmentsFilterAction,
			mainListPagination, mainListSort,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);
		const therapyId = this.calculateTherapyId();

		// Set filter so that the change will be detected after IO
		setPatientRecordAppointmentsFilterAction(oFilter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(
				loadPatientRecordAppointmentsListAction, patientId, therapyId, filter, 1, perPage, sortBy, sortDir
			))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (!areAllObjectPropsEmpty(oFilter, true, false)) {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open item dialog
	 * @param {?AppointmentsListItemDataObject} [appointmentListItem=null]
	 */
	openItemDialog(appointmentListItem = null) {
		const {patientId, openDialogAction} = this.props;
		const isNew = !getString(appointmentListItem, 'id');
		
		const dialogGUIID = openDialogAction('', AppointmentDialog, {
			isNew,
			data: (!isNew ? updateAppointmentDataMap.inputFromList(appointmentListItem) : undefined),
			patientId,
			therapyId: this.calculateTherapyId(),
			onSave: () => this.reloadMainList(),
			onDelete: () => {
				const {therapyId} = this.props;

				// Reload patient record to update global statistics
				this.executeAbortableAction(loadPatientRecordAction, patientId).then();
				// Reload patient therapy to update statistics
				if (!!therapyId && therapyId !== '*') {
					this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section').then();
				}

				// Reload main list data
				// @note This is done asynchronously on purpose because there is no reason to wait for this to 
				// finish before continuing.
				this.reloadMainList()
					// Go to the previous page if there are no table rows after one has been deleted
					.then(() => {
						const mainList = getArray(this.props, 'mainList');
						const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
						if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
					});
			}
		}, {
			id: 'create-appointment-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Delete main list item
	 * @param {AppointmentsListItemDataObject} item - Main list popup item.
	 * @return {Promise<void>}
	 */
	deleteMainListItem(item) {
		const {
			patientId, therapyId, openDialogAction, closeDialogAction, deleteAppointmentsItemAction, 
			addSuccessMessageAction, loadPatientRecordAction, loadPatientTherapyAction 
		} = this.props;

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_delete'),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteAppointmentsItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.t('delete_success_msg'));

								// Reload patient record to update global statistics
								this.executeAbortableAction(loadPatientRecordAction, patientId).then();
								// Reload patient therapy to update statistics
								if (!!therapyId && therapyId !== '*') {
									this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section').then();
								}
								
								// Reload main list data
								// @note This is done asynchronously on purpose because there is no reason to wait for this to 
								// finish before continuing.
								this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve('yes'));
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve('no');
				}
			}, {
				id: 'delete-appointment-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 650
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}


	// Event handling methods -------------------------------------------------------------------------------------------
	/**
	 * Handle event triggered after deleting multiple main list items
	 */
	handleMultiDeleteMainListItems() {
		// Clear selection to unset deleted items
		if (this.mainListTableRef) this.mainListTableRef.clearSelection();

		// Reload main list data
		this.reloadMainList()
			// Go to the previous page if there are no table rows after one has been deleted
			.then(() => {
				const mainList = getArray(this.props, 'mainList');
				const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
				if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
			});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {AppointmentsListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions standard-actions-row">
				<Tooltip
					tag="div"
					title={this.t('delete_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_delete_symbol}
						onClick={() => this.deleteMainListItem(row)}
					/>
				</Tooltip>

				<Tooltip
					tag="div"
					title={this.t('edit_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_edit_symbol}
						onClick={() => this.openItemDialog(row)}
					/>
				</Tooltip>
			</div>
		);
	}

	render() {
		const {active, displayType, mainList, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {therapistCodebook, officeLocationCodebook} = this.state;

		return (
			<div className={`${styles['wrapper']} ${!active ? 'no-display' : ''}`}>
				<SimpleStaticSearch
					styleName="compact"
					className={`main-search rows-2 ${styles['filter']}`}
					collapsable={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'dateFrom',
							this.t('dateFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateTo',
							this.t('dateToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
							}
						),
						new SimpleStaticSearchOptionObject(
							'officeLocationId',
							this.t('officeLocationField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(officeLocationCodebook).map(i => ({label: i.name, value: i.id})),
								onMenuOpen: () => {
									if (!isset(officeLocationCodebook)) this.loadPageCodebook('officeLocation').then();
								}
							}
						),
						new SimpleStaticSearchOptionObject(
							'therapistId',
							this.t('therapistField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(therapistCodebook).map(i => ({label: i.name, value: i.id})),
								isLoading: (therapistCodebook === null),
								onMenuOpen: () => {
									if (!isset(therapistCodebook)) this.loadPageCodebook('therapist').then();
								}
							}
						),
					]}
					value={mainListFilter}
					title={(
						<>
							<Button
								className="no-border-radius"
								icon={icon_font_create_symbol}
								label={this.t(
									displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
										'schedule_appointment_desc_single' : 'schedule_appointment_desc_all',
									'PatientRecord'
								)}
								displayStyle={
									displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
										BUTTON_STYLE.ACTION : BUTTON_STYLE.ERROR
								}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								onClick={e => {
									e.stopPropagation();
									e.nativeEvent.stopImmediatePropagation();
									this.openItemDialog();
								}}
							/>
							<div className="horizontal-separator" />
							<AppointmentsMainTableToolbar
								noStyle={true}
								selectedMainListItems={getArray(this.mainListTableRef, 'state.selectedRows')}
								onAfterDelete={() => this.handleMultiDeleteMainListItems()}
								ref={node => { this.mainListToolbarRef = node; }}
							/>
						</>
					)}
					applied={!this.isMainListFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				<DataTable
					id="patient-record-appointments-table"
					className="standard sticky-last-column"
					responsive={true}
					responsiveBreakpointName="bp-xl"
					selectable={true}
					primaryKeyColumn="id"
					highlightOnHover={true}
					columns={[
						{
							sortName: 'appointmentDateTime',
							name: 'date',
							label: this.t('dateField'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
								outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT.LONG),
								whiteSpace: 'nowrap'
							}),
							width: 1,
						},
						{
							label: this.t('timeField'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									!!row.startTime || !!row.endTime ?
										<Label
											element="span"
											elementProps={{className: 'no-wrap'}}
											content={`${row.startTime} ⇒ ${row.endTime}`}
										/> : '—'
								),
							}),
							width: 1,
						},
						{
							name: 'officeLocation',
							label: this.t('officeLocationField'),
							minWidth: 150,
							emptyAsDefault: true,
							defaultValue: '—',
						},
						{
							name: 'therapist',
							label: this.t('therapistField'),
							minWidth: 150,
							emptyAsDefault: true,
							defaultValue: '—',
						},

						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								standardWrapper: false,
								content: this.renderActions,
							}),
							stopPropagation: true,
							width: 1,
						}
					]}
					data={mainList}
					paginationType={PAGINATION_TYPE.DYNAMIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					onSelect={selected => {if(this.mainListToolbarRef) this.mainListToolbarRef.updateSelection(selected)}}
					{...mainListPagination}
					{...mainListSort}
					ref={node => { this.mainListTableRef = node; }}
				/>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Appointments.propTypes = {
	active: PropTypes.bool,
	displayType: PropTypes.oneOf(PATIENT_RECORD_DISPLAY_TYPES),
};

export default connect(
	mapStateToProps, getPageActions({
		...actions, loadPatientTherapyAction, loadPatientRecordAction
	}), null, {forwardRef: true}
)(Appointments);