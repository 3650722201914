/**
 * Data object representing the expert report types list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class ExpertReportTypesListItemDataObject {
	/**
	 * @param {string} id - DB ID of the expert report type.
	 * @param {string} name - Name of the expert report type.
	 */
	constructor(id, name) {
		this.id = id;
		this.name = name;
	}
}

/**
 * Data object representing the expert report types item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class ExpertReportTypesItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [name=''] - Item's name.
	 * @param {string} [content=''] - Content section text of the expert report type.
	 * @param {?string} [templateFileId=null] - DB ID of the uploaded template file.
	 * @param {string} [templateFileName=''] - Name fo the uploaded template file.
	 */
	constructor(id = null, name = '', content = '', templateFileId = null, templateFileName = '') {
		this.id = id;
		this.name = name;
		this.content = content;
		this.templateFileId = templateFileId;
		this.templateFileName = templateFileName;
	}
}