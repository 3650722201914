import "./index.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import RichTextInput from "Core/components/input/RichTextInput";
import PropTypes from "prop-types";

class ExpertReportInput extends BaseComponent {
	render() {
		const {className, value, bounds} = this.props;
		
		return (
			<RichTextInput
				className={`expert-report-input-component ${className}`}
				value={value || ''}
				bounds={bounds}
				toolbar={[
					['undo', 'redo'],
					[{size: ["0.875em", false, "1.5em", "2.5em"]}],
					['bold', 'italic', 'underline', 'strike'],
					[
						{
							'background':
								// Colors supported by docx files
								[
									'#000000', '#0000FF', '#00FFFF', '#008000', '#FF00FF', '#FF0000', '#FFFF00',
									'#FFFFFF', '#00008B', '#008B8B', '#006400', '#8B008B', '#8B0000', '#8B8B00', 
									'#A9A9A9', '#D3D3D3',
								]
						},
						{
							'color': []
						}
					],
					[{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
					[{'list': 'ordered'}, {'list': 'bullet' }],
					['clean'],
				]}
				onChange={v => executeComponentCallback(this.props.onChange, v)}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ExpertReportInput.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	bounds: PropTypes.string,
	
	onChange: PropTypes.func, // Arguments: {string} content
};

/**
 * Define component default values for own props
 */
ExpertReportInput.defaultProps = {
	className: '',
};

export default ExpertReportInput;