import {pagination_default_per_page} from "Config/app";
import {SORT_ORDER} from "Core/const/global";

export class IoJsonResponseObject {
	constructor(success, errorCode = '', errorMessage = '', data = null) {
		this.success = success;
		this.errorCode = errorCode;
		this.errorMessage = errorMessage;
		this.data = data;
	}
}

/**
 * IO response object for JSON fetch request
 */
export class IoJsonFetchResponseObject extends IoJsonResponseObject {
	constructor(
		success, errorCode = '', errorMessage = '', data = null, query = '', filter = null, pageNo = 1, 
		perPage = pagination_default_per_page, sortBy = '', sortDir = SORT_ORDER.DESC, totalResults, isLastPage
	) {
		super(success, errorCode, errorMessage, data);
		this.query = query;
		this.filter = filter;
		this.pageNo = pageNo;
		this.perPage = perPage;
		this.sortBy = sortBy;
		this.sortDir = sortDir;
		this.totalResults = totalResults;
		this.isLastPage = isLastPage;
	}
}

/**
 * IO response object for JSON fetch item request
 */
export class IoJsonFetchItemResponseObject extends IoJsonResponseObject {
	constructor(success, errorCode = '', errorMessage = '', data = null, id) {
		super(success, errorCode, errorMessage, data);
		this.id = id;
	}
}

/**
 * Data response processor result object
 */
export class IoDataResponseProcessorResultObject {
	constructor(data, response) {
		this.data = data;
		this.response = response;
	}
}