import {cloneDeep, get} from "lodash";
import {getBool, getString} from "Core/helpers/data";
import {CalendarItemDataObject} from "Components/advanced/ScheduleCalendar/dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {APPOINTMENT_PATIENT_TYPE} from "Const/patient";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CalendarItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	const used = getBool(rawData, 'used');
	let classNames = !!get(result, 'styleClass') ? [get(result, 'styleClass')] : [];
	if (used) classNames.push('used-appointment');
	
	result = new CalendarItemDataObject({
		patientType: (getBool(result, 'anonymous') ? 
			APPOINTMENT_PATIENT_TYPE.ANONYMOUS_PATIENT : 
			APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT
		),
		id: getString(result, 'id'),
		title: getString(result, 'title'),
		start: getDate(get(result, 'startDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		end: getDate(get(result, 'endDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		classNames,
		backgroundColor: getString(result, 'backgroundColor'),
		textColor: getString(result, 'textColor'),
		used: getBool(result, 'used'),
		visitId: getString(result, 'visitMadeByAppointment.uniqueID'),
		patientId: getString(result, 'visitMadeByAppointment.patientId'),
	});

	return result;
}