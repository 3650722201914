import './index.css';

import React from 'react';
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getGlobalActions} from 'Core/helpers/redux';
import * as actions from "./actions";
import {reducerStoreKey} from "./reducer";
import {selectors} from "Core/store/reducers";
import FormField, {FORM_FIELD_LABEL_POSITION} from 'Core/components/advanced/FormWrapper/FormField';
import PatientSelectInput from 'Components/input/PatientSelectInput';
import {ASYNC_SELECT_INPUT_TOOLBAR_POSITION} from 'Core/components/input/SelectAsyncInput/const';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import Label from 'Core/components/display/Label';
import {PATIENT_QUICK_SEARCH_CREATE_POSITION, PATIENT_QUICK_SEARCH_CREATE_POSITIONS} from './const';
import {isDialogOpened} from 'Core/helpers/dialog';
import PatientDialog from 'Components/dialogs/PatientDialog';
import {trimArray} from 'Core/helpers/data';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	selectedPatient: selectors[reducerStoreKey].getSelectedPatient(state),
});

class PatientQuickSearch extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'PatientQuickSearch',
			domPrefix: 'patient-quick-search',
		});

		// Actions methods
		this.handleCreateClick = this.handleCreateClick.bind(this);

		// Render methods
		this.renderLabel = this.renderLabel.bind(this);
	}

	handleCreateClick(e) {
		const {openDialogAction, closeDialogAction} = this.props;
		const createDialogGUIID = 'create-patient';
		const createDialogId = 'create-patient-dialog';
		
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		
		if (!isDialogOpened(createDialogGUIID)) {
			openDialogAction(createDialogGUIID, PatientDialog, {
				isNew: true,
				dialogId: createDialogId,
				onSave: patient => {
					closeDialogAction(createDialogGUIID);
					executeComponentCallback(this.props.onCreate, patient);
				},
			}, {
				id: createDialogId,
				className: 'bordered-title',
				closeOnEscape: true,
				closeOnClickOutside: false,
				hideCloseBtn: true,
				maxWidth: 970
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), createDialogGUIID], 'left')
			);
		}
	}
	
	renderLabel() {
		const {label, createButtonPosition} = this.props;
		
		return (
			createButtonPosition === PATIENT_QUICK_SEARCH_CREATE_POSITION.SELECT ?
				label
			: createButtonPosition === PATIENT_QUICK_SEARCH_CREATE_POSITION.LABEL ?
				<>
					<div className="content"><Label content={label} /></div>
					<div className="actions">
						{executeComponentCallback(this.props.renderAdditionalButtons)}
						<div className="action-button main-button">
							<Button
								big={true}
								icon="user-plus"
								label={this.t('create_patient')}
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.SOLID}
								title={this.t('create_patient_shortcut')}
								onClick={this.handleCreateClick}
							/>
						</div>
					</div>
				</>	
			: null
		);
	}

	render() {
		const {
			className, selectedPatient, isClearable, autoFocus, showCreate, createButtonPosition, blurOnEscape,
			setQuickSearchPatientAction
		} = this.props;
		
		let toolbarButtons = [
			{
				className: 'search-btn',
				position: ASYNC_SELECT_INPUT_TOOLBAR_POSITION.LEFT,
				icon: 'search',
				displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
				onClick: () => document.getElementById(this.getDomId()).querySelector('.form-field-label').click(),
			}
		];
		if (showCreate && createButtonPosition === PATIENT_QUICK_SEARCH_CREATE_POSITION.SELECT) {
			toolbarButtons.push({
				position: ASYNC_SELECT_INPUT_TOOLBAR_POSITION.RIGHT,
				icon: 'plus',
				label: this.t('create_patient'),
				displayStyle: BUTTON_STYLE.ACTION,
				displayType: BUTTON_DISPLAY_TYPE.SOLID,
				onClick: this.handleCreateClick
			});
		}
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${className}`}>
				<FormField 
					inputClassName={
						`full ${createButtonPosition === PATIENT_QUICK_SEARCH_CREATE_POSITION.SELECT ? 'with-actions' : ''}`
					}
					labelClassName={
						createButtonPosition === PATIENT_QUICK_SEARCH_CREATE_POSITION.LABEL ? 'with-actions' : ''
					}
					label={this.renderLabel()} 
					labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
				>
					<PatientSelectInput
						autoFocus={autoFocus}
						isClearable={isClearable}
						blurOnEscape={blurOnEscape}
						toolbarButtons={toolbarButtons}
						value={selectedPatient}
						placeholder={this.t('placeholder')}
						onChange={o => {
							setQuickSearchPatientAction(o);
							executeComponentCallback(this.props.onChange, o);
						}}
					/>
				</FormField>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientQuickSearch.propTypes = {
	// Wrapper element's id attribute
	id: PropTypes.string,
	// Wrapper element's class attribute
	className: PropTypes.string,
	// Select input label
	label: PropTypes.string,
	// Flag that specifies if search input will be focused when select component mounts
	autoFocus: PropTypes.bool,
	// Flag that specifies if search select can be cleared
	isClearable: PropTypes.bool,
	// Flag that specifies if create button will be rendered
	showCreate: PropTypes.bool,
	// Position of the creat button
	// @note Relevant only if 'showCreate' prop is true.
	createButtonPosition: PropTypes.oneOf(PATIENT_QUICK_SEARCH_CREATE_POSITIONS),
	// Function that should render additional toolbar buttons (apart from the create button)
	renderAdditionalButtons: PropTypes.func,
	// Flag that specifies if select input will blur on Escape key before the default event propagation will kick in
	blurOnEscape: PropTypes.bool,

	// Event that will be triggered after selected patient was changed
	// @param {PatientSelectOptionDataObject} patient - Selected patient.
	onChange: PropTypes.func,
	// Event that will be triggered after the patient was successfully created
	// @param {Object} rawData
	onCreate: PropTypes.func,
};

/**
 * Define component default values for own props
 */
PatientQuickSearch.defaultProps = {
	id: '',
	className: '',
	label: '',
	autoFocus: false,
	isClearable: false,
	showCreate: false,
	createButtonPosition: PATIENT_QUICK_SEARCH_CREATE_POSITION.SELECT,
	blurOnEscape: true,
};

export * from './actions';
export * from './const';
export {PatientSelectOptionDataObject} from 'Components/input/PatientSelectInput';
export default connect(mapStateToProps, getGlobalActions(actions))(PatientQuickSearch);