import "./default.style.css";
import "./card.style.css";

import React from "react";
import DataComponent, {executeComponentCallback} from 'Core/components/DataComponent';
import DataValueValidation from "Core/validation";
import Label from "Core/components/display/Label";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput, {PasswordStrengthPreview, TEXT_INPUT_LABEL_POSITION} from "Core/components/input/TextInput";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {connect} from "react-redux";
import {getGlobalActions} from "Core/helpers/redux";
import PropTypes from "prop-types";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {omit} from "lodash";
import {icon_font_save_symbol} from "Config/app";
import {getString, isset} from "Core/helpers/data";

class PasswordReset extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				currentPassword: '',
				newPassword: '',
				confirmPassword: ''
			},
		}, {
			translationPath: 'PasswordReset',
			disableLoad: true
		});

		// Action methods
		this.resetPassword = this.resetPassword.bind(this);
	}

	// DataValueValidation methods --------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const {showEnterCurrentPassword} = this.props;
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		if (showEnterCurrentPassword) dataValidation.addRule('currentPassword', 'required');
		dataValidation.addRule('newPassword', 'required', 'password');
		dataValidation.addRule('confirmPassword', 'required', {
			name: 'sameAs', options: { field: 'newPassword', label: this.t('password_label') }
		});

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		return !validationErrors;
	}

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Reset user's password
	 * @return {Promise<any>}
	 */
	resetPassword() {
		const currentPassword = this.getValue('currentPassword');
		const password = this.getValue('newPassword');
		const confirmPassword = this.getValue('confirmPassword');

		return this.clearValidationErrors()
			.then(() => this.clearErrorMessage())
			.then(() => {
				if (this.validate()) {
					return executeComponentCallback(this.props.action, password, confirmPassword, currentPassword);
				}
			});
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			styleName, className, showAppName, appName, showFormTitle, formTitle, showFormDesc, formDesc, showInputLabel, 
			inputLabelPosition, buttonProps, renderToTop, renderToBottom, showEnterCurrentPassword, passwordInstructions
		} = this.props;
		const error = this.getErrorMessage();
		
		return (
			<div id={this.getDomId()} className={`password-reset-component ${styleName}-style ${className}`}>
				{renderToTop ? renderToTop : null}
				
				{/* Header --------------------------------------------------------------------------------------------- */}
				<div className="password-reset-header">
					{
						showAppName ?
							<div className="app-name">
								{isset(appName) ? appName : this.t('title', 'App')}
							</div>
							: null
					}

					{
						showFormTitle || showFormDesc ?
							<>
								<div className="form-title">
									{showFormTitle ? (isset(formTitle) ? formTitle : this.t('form_title')) : null}
									{
										showFormDesc ?
											(
												isset(formDesc) ?
													(
														typeof formDesc === 'string' ?
															<Label
																element="div"
																elementProps={{className: 'form-desc'}}
																content={formDesc}
																supportHtml={true}
															/>
															:
															formDesc
													)
													:
													<Label
														element="div"
														elementProps={{className: 'form-desc'}}
														content={this.t('form_description')}
														supportHtml={true}
													/>
											)
											: null
									}
								</div>
							</>
							: null
					}
				</div>


				{/* Content -------------------------------------------------------------------------------------------- */}
				<div className="password-reset-content">
					<FormWrapper>

						{
							showEnterCurrentPassword ?
								<FormField
									label={this.t('current_password_label')}
									labelPosition={showInputLabel ? inputLabelPosition : FORM_FIELD_LABEL_POSITION.NONE}
									errorMessages={this.getValidationErrors('currentPassword')}
									required={true}
								>
									<TextInput
										type="password"
										name="currentPassword"
										value={this.getValue('currentPassword')}										
										placeholder={this.t('current_password_placeholder')}
										onChange={this.handleInputChange}
										onEnterKey={this.resetPassword}
										autoComplete="new-password"
									/>
								</FormField>
								: null
						}
						
						<FormField
							label={this.t('password_label')}
							labelPosition={showInputLabel ? inputLabelPosition : FORM_FIELD_LABEL_POSITION.NONE}
							errorMessages={this.getValidationErrors('newPassword')}
							defaultMessages={passwordInstructions ? [passwordInstructions] : []}
							required={true}
						>
							<TextInput
								type="password"
								name="newPassword"
								value={this.getValue('newPassword')}
								showLabel={true}
								labelClass="no-padding"
								labelWidth={50}
								labelPosition={TEXT_INPUT_LABEL_POSITION.RIGHT}
								previewAsLabel={true}
								getPreview={PasswordStrengthPreview}
								placeholder={this.t('password_placeholder')}
								onChange={this.handleInputChange}
								onEnterKey={this.resetPassword}
								autoComplete="new-password"
								updateWhenChangedWithoutFocus={true}
							/>
						</FormField>

						<FormField
							label={this.t('confirm_password_label')}
							labelPosition={showInputLabel ? inputLabelPosition : FORM_FIELD_LABEL_POSITION.NONE}
							errorMessages={this.getValidationErrors('confirmPassword')}
							required={true}
						>
							<TextInput
								type="password"
								name="confirmPassword"
								value={this.getValue('confirmPassword')}
								placeholder={this.t('confirm_password_placeholder')}
								onChange={this.handleInputChange}
								onEnterKey={this.resetPassword}
								autoComplete="new-password"
								updateWhenChangedWithoutFocus={true}
							/>
						</FormField>
					</FormWrapper>
				</div>

				
				{/* Actions -------------------------------------------------------------------------------------------- */}
				<div className="password-reset-actions">
					<Button
						className={`set-password-btn ${getString(buttonProps, 'className')}`}
						label={this.t('action_btn')}
						onClick={this.resetPassword}
						{...PasswordReset.defaultProps.buttonProps}
						{...omit(buttonProps, ['className', 'onClick'])}
					/>
				</div>

				{renderToBottom ? renderToBottom : null}

				{error ? <p className={`component-error error-color`}>{error}</p> : null}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PasswordReset.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Password reset component wrapper element ID class attribute
	id: PropTypes.string,
	// Password reset component wrapper element class attribute
	className: PropTypes.string,

	// Flag that specifies if app name will be rendered
	showAppName: PropTypes.bool,
	// App name to render instead of default app name
	// @note If undefined, default app name will be rendered.
	appName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if password reset form title will be rendered
	showFormTitle: PropTypes.bool,
	// Password reset form title to render instead of default password reset form title
	// @note If undefined, default form title will be rendered.
	formTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if password reset form description will be rendered
	showFormDesc: PropTypes.bool,
	// Password reset form description to render instead of default password reset form description
	// @note If undefined, default form description will be rendered.
	formDesc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if password reset form input fields should have labels
	showInputLabel: PropTypes.bool,
	// Flag that specifies if current (old) password field will be rendered
	showEnterCurrentPassword: PropTypes.bool,
	// Password reset component input fields label position
	// @see FORM_FIELD_LABEL_POSITION const.
	inputLabelPosition: PropTypes.string,
	// Instructions for choosing a password
	passwordInstructions: PropTypes.string,
	
	// Main button component props
	buttonProps: PropTypes.object,
	// Main submit function
	action: PropTypes.func, // Arguments: password, confirmPassword, currentPassword

	// Anything to render at the top of the login component
	renderToTop: PropTypes.element,
	// Anything to render at the bottom of the login component
	// @note Only errors will be rendered below this.
	renderToBottom: PropTypes.element
};

/**
 * Define component default values for own props
 */
PasswordReset.defaultProps = {
	styleName: 'default',
	className: '',
	showAppName: false,
	appName: undefined,
	showFormTitle: true,
	formTitle: undefined,
	showFormDesc: true,
	formDesc: undefined,
	showInputLabel: true,
	showEnterCurrentPassword: false,
	inputLabelPosition: FORM_FIELD_LABEL_POSITION.STACKED,
	buttonProps: {
		icon: icon_font_save_symbol,
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		big: true
	},

	renderToTop: null,
	renderToBottom: null,
};

export default connect(
	null, getGlobalActions(), null, {forwardRef: true}
)(PasswordReset);