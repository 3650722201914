import styles from "./index.module.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {isFunction, isString, isEqual, omit, set, get} from "lodash";
import {TEXT_INPUT_LABEL_POSITION, TEXT_INPUT_TOOLBAR_POSITION, TEXT_INPUT_TOOLBAR_POSITIONS} from "./const";
import {getArray, getNumber, getString, isset} from "Core/helpers/data";
import {icon_font_error_symbol} from "Config/app";
import {Tooltip} from "react-tippy";
import Icon from "Core/components/display/Icon";
import {openDialog} from "Core/helpers/dialog";
import Button, {
	BUTTON_DISPLAY_TYPE, BUTTON_DISPLAY_TYPES, BUTTON_STYLE, BUTTON_STYLES
} from "Core/components/display/Button";
import {INSERT_VALUE_BUTTON_TYPE, INSERT_VALUE_BUTTON_TYPES} from "Core/components/advanced/InsertValueButton/const";
import InsertValueButton from "Core/components/advanced/InsertValueButton";
import {spliceStr} from "Core/helpers/string";

/**
 * Text input component
 * @description Text input component that does not update parent component's state on each key press ('fast' component).
 * @note This component uses internal state to handle keypress updates re-rendering only itself until used hits the
 * 'Enter' key or textarea (inputRef) looses focus. This fixes one of the main performance issues in React forms or
 * pages with lost of input fields where each keypress rerender the whole form or page.
 */
class TextInput extends DataComponent {
	/**
	 * Ref used for external reference (for example for inserting values)
	 * @type {Object}
	 */
	inputRef = null;
	/**
	 * Current cursor position of the input ref
	 * @type {number}
	 */
	inputRefCursorPos = 0;

	constructor(props, data, options) {
		super(props, {
			// Text input's current value
			data: '',
			// Text input's preview value
			// @note Preview value can be displayed in the text input's label section if  'previewAsLabel' prop is true.
			preview: '',
			
			/**
			 * List of options to suggest while typing
			 * @type {string[]}
			 */
			autosuggestOptions: [],
			
			...data
		},  {
			translationPath: 'TextInput',
			domPrefix: 'text-input-component',
			dataPropAlias: 'value',
			enableLoadOnDataPropChange: true,
			domManipulationIntervalTimeout: 1,

			...options
		});

		// Cursor methods
		this.updateCursorPosition = this.updateCursorPosition.bind(this);

		// Data methods
		this.insertValue = this.insertValue.bind(this);
		this.updateValue = this.updateValue.bind(this);
		
		// Event handler methods
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleInputClick = this.handleInputClick.bind(this);
		this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleInputPaste = this.handleInputPaste.bind(this);
		this.handleInputFocus = this.handleInputFocus.bind(this);

		// Label and preview methods
		this.updatePreview = this.updatePreview.bind(this);
		this.openLabelDialog = this.openLabelDialog.bind(this);
		this.handleLabelClick = this.handleLabelClick.bind(this);
		
		// Insert value methods
		this.containsInsertValue = this.containsInsertValue.bind(this);
		
		// Autosuggest methods
		this.loadAutosuggestOptions = this.loadAutosuggestOptions.bind(this);

		// Render methods
		this.renderSuggestions = this.renderSuggestions.bind(this);
		this.renderInsertButton = this.renderInsertButton.bind(this);
		this.renderClearButton = this.renderClearButton.bind(this);
		this.renderInput = this.renderInput.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);

		await this.updatePreview();

		return Promise.resolve();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.getOption('enableLoadOnDataPropChange', false)) {
			const dataPropName = this.getDataPropName();
			const data = (dataPropName ? this.props[this.getDataPropName()] : this.props);
			const prevData = (dataPropName ? prevProps[this.getDataPropName()] : prevProps);

			// If prop data changes load it into local component's state
			// NOTE: Only crude comparison is done (no custom data class support) because load method will check that 
			// before changing local state. This simple check is done just to prevent calling load method on every prop
			// change for optimization.
			if (!isEqual(data, prevData)) {
				return this.load(data)
					// Update preview
					.then(() => this.updatePreview());
			}
		}
		return Promise.resolve(this.state);
	}


	// DOM manipulation interval methods --------------------------------------------------------------------------------
	/**
	 * Method called on each DOM manipulation interval
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element
	 * is not set.
	 */
	domManipulations(element) {
		// Calculate and apply input element padding to allow space for input toolbars
		if (this.inputRef) {
			const inputToolbarL = this.inputRef
				.closest('.text-input-wrapper')
				.querySelector('.input-toolbar.position-left');
			if (inputToolbarL) this.inputRef.style.paddingLeft = `calc(0.5rem*1.5 + ${inputToolbarL.offsetWidth}px)`;
			else this.inputRef.style.paddingLeft = '';
			
			const inputToolbarRight = this.inputRef
				.closest('.text-input-wrapper')
				.querySelector('.input-toolbar.position-right');
			if (inputToolbarRight) this.inputRef.style.paddingRight = `calc(0.5rem*1.5 + ${inputToolbarRight.offsetWidth}px)`;
			else this.inputRef.style.paddingRight = '';
		}
	}


	// Cursor methods ---------------------------------------------------------------------------------------------------
	/**
	 * Update input ref cursor position local variable
	 */
	updateCursorPosition() {
		if (this.inputRef) {
			this.inputRefCursorPos = getNumber(this.inputRef, 'selectionEnd', this.inputRef?.value.length);
		}
	}

	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {any|null} Local component's state compatible data or null if data could not be loaded.
	 */
	getDataToLoad(rawData) { return getString(rawData); }


	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Programmatically insert value into text input DOM element at cursor position
	 * @param {string|number} value - Value to insert.
	 */
	insertValue(value) {
		const {readOnly} = this.props;
		
		if (this.inputRef && !readOnly) {
			const insertValue = getString(value);

			// Delete selected text first
			if (this.inputRef.selectionStart !== this.inputRef.selectionEnd) {
				const inputRefOriginalCursorStartPos = getNumber(this.inputRef,'selectionStart',this.inputRef.value.length);
				this.inputRef.value = spliceStr(
					this.inputRef?.value,
					this.inputRef.selectionStart,
					Math.abs((this.inputRef.selectionEnd - this.inputRef.selectionStart))
				);
				this.inputRef.selectionStart = inputRefOriginalCursorStartPos;
				this.inputRef.selectionEnd = inputRefOriginalCursorStartPos;
				this.updateCursorPosition();
			}

			// Get text input current cursor position
			const inputRefCursorPos = this.inputRefCursorPos;

			// Insert value an current cursor position
			this.inputRef.value =
				this.inputRef?.value.slice(0, inputRefCursorPos) +
				insertValue +
				this.inputRef?.value.slice(inputRefCursorPos)
			;
			this.state.data = this.inputRef.value;

			// Trigger change
			let ev = new Event('input', { bubbles: true });
			ev.simulated = true;
			this.inputRef.dispatchEvent(ev);
			let evBlur = new Event('blur', { bubbles: true });
			evBlur.simulated = true;
			this.inputRef.dispatchEvent(evBlur);

			// Return focus to the input element (required for inputs that use blur event to trigger updates)
			const _i = setInterval(() => {
				if (document.activeElement === this.inputRef) clearInterval(_i);
				else {
					this.inputRef.focus();
					// Select the newly inserted value
					this.inputRef.selectionStart = inputRefCursorPos;
					this.inputRef.selectionEnd = inputRefCursorPos + insertValue.length;
				}
			}, 10);
		}
	}

	/**
	 * Programmatically update text input DOM element's value
	 * @param {string|number} value - Value to set.
	 */
	updateValue(value) {
		const {readOnly} = this.props;
		
		if (this.inputRef && !readOnly) {
			// Update input's content
			this.inputRef.value = getString(value);
			this.state.data = this.inputRef.value;

			// Move cursor to the start of the input
			this.inputRef.setSelectionRange(0, 0);

			// Trigger change
			let ev = new Event('input', { bubbles: true });
			ev.simulated = true;
			this.inputRef.dispatchEvent(ev);
			let evBlur = new Event('blur', { bubbles: true });
			evBlur.simulated = true;
			this.inputRef.dispatchEvent(evBlur);

			// Return focus to the input element (required for inputs that use blur event to trigger updates)
			const _i = setInterval(() => {
				if (document.activeElement === this.inputRef) clearInterval(_i);
				else this.inputRef.focus();
			}, 10);
		}
	}

	/**
	 * Trigger component's onChange event with component's main data as param
	 *
	 * @param {Event|SyntheticEvent} event - JavaScript change event.
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	update(event) {
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();

		// Update preview
		return this.updatePreview().then(() => {
			// Trigger onChange event
			executeComponentCallback(this.props.onChange, event);
		});
	}


	// Event handler methods --------------------------------------------------------------------------------------------
	/**
	 * Handle input component changes
	 *
	 * @param {Event|SyntheticEvent} event - DOM element's event object. Component's main data item or main data item 
	 * field name (if component's main data item is an object) and new value will be extracted from the event object. By 
	 * convention DOM element should have a 'name' attribute that corresponds to a single component's main data item 
	 * field if component's main data item is an object. If 'name' attribute is not specified component's main data item 
	 * will be updated with the new value.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleInputChange(event) {
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();
		
		const value = getString(event, 'target.value');

		return this.setData(value)
			// Clear data if actual input value is empty but component data is not
			// @note This usually happens when switching from insert value input to component main input on components that 
			// extend this component since this component usually allows any value.
			.then(() => {
				if (this.inputRef.value !== this.getData()) {
					set(event, 'target.value', this.inputRef.value);
					return this.setData(this.inputRef.value).then(() => this.inputRef.focus());
				}
			})
			// Update preview
			.then(() => this.updatePreview())
			// Trigger 'onInput' event
			.then(state => { executeComponentCallback(this.props.onInput, event); return {...state}; })
			// Update component if it is not in fast mode
			.then(state =>
				!this.getProp('isFast') ||
				(!!this.getProp('updateWhenChangedWithoutFocus') && document.activeElement !== event.target) ? 
					this.update(event) : state
			)
			// Load autosuggest options
			.then(state => this.loadAutosuggestOptions(value).then(() => state));
	}

	/**
	 * Input element key down handler
	 * @param {KeyboardEvent|SyntheticEvent} event - JavaScript key down event.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleKeyDown(event) {
		const {value, clearOnEsc} = this.props;
		const stateValue = this.state.data;

		if(event.key === 'Escape') {
			// Stop propagation of the escape key press
			// @description This is done to prevent parent elements from catching the escape key press while this input 
			// element is focused because we explicitly want escape key to be handled by it. For example, this is necessary
			// to prevent closing a dialog, that closes on escape key press, when escape key is pressed on a focused input.
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();
			
			if (clearOnEsc) {
				// If current value is not empty, clear the value and trigger the 'onClear' event
				if (getString(value)) {
					this.updateValue('');
					executeComponentCallback(this.props.onClear, event);
				}
				// If current value is empty, blur the input element
				// @note This will in turn re-enable propagation so that dialogs can close next time escape key is pressed.
				else {
					this.inputRef.blur();
					return Promise.resolve({...this.state});
				}
			} else {
				// If current value is changes but component didn't update (user typed something but didn't press enter or 
				// moved to another element)
				if (getString(stateValue) !== getString(value)) {
					// Reset value to the original one (props value)
					return this.setData(value);
				}
				// If input have not changed 
				else {
					// Blur the input element
					// @note This will in turn re-enable propagation so that dialogs can close next time escape key is pressed.
					this.inputRef.blur();
					return Promise.resolve({...this.state});
				}
			}
		}
		
		executeComponentCallback(this.props.onKeyDown, event);
	}

	/**
	 * Handle input element click event
	 * @param {MouseEvent} event - Input element click event.
	 */
	handleInputClick(event) {
		if (!this.getProp('readOnly')) this.updateCursorPosition();
		executeComponentCallback(this.props.onClick, event);
	}

	/**
	 * Handle input element key up event
	 * @param {KeyboardEvent} event - Input element key up event.
	 */
	handleInputKeyUp(event) {
		this.updateCursorPosition();
		executeComponentCallback(this.props.onKeyUp, event);
	}

	/**
	 * Handle input element key press event
	 * @param {KeyboardEvent} event - Input element key press event.
	 */
	handleKeyPress(event) {
		super.handleInputEnterPress(event);
		executeComponentCallback(this.props.onKeyPress, event);
	}

	/**
	 * Handle input element paste event
	 * @param {Event} event - Input element paste event.
	 */
	handleInputPaste(event) {
		executeComponentCallback(this.props.onPaste, event);
	}

	/**
	 * Handle input element focus event
	 * @param {FocusEvent} event - Input element focus event.
	 */
	handleInputFocus(event) {
		if (!this.getProp('readOnly')) this.updateCursorPosition();
		executeComponentCallback(this.props.onFocus, event);
	}


	// Label and preview methods ----------------------------------------------------------------------------------------
	/**
	 * Generate the preview for a specific value and update it in the local state
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	updatePreview() {
		const {getPreview} = this.props;
		if (isFunction(getPreview)) {
			const previewResult = getPreview(this.getData());
			if (previewResult instanceof Promise) return previewResult.then(preview => this.setState({ preview }));
			else return this.setState({ preview: previewResult });
		} else {
			return this.setState({ preview: this.getData() });
		}
	}

	/**
	 * Open label dialog
	 * @note Dialog is a global dialog component that can render any component with props passed to it.
	 *
	 * @param {any} component - Dialog component to render inside the dialog.
	 * @param {object} props - Dialog component props.
	 * @param {object} options - Dialog options.
	 */
	openLabelDialog({component, props, options = {}}){
		const {disabled} = this.props;

		if(!disabled) {
			openDialog('', component, {
				onInsert: this.insertValue,
				onSave: this.updateValue,
				...props
			}, {
				closeOnClickOutside: true,
				closeOnEscape: true,
				...options,
			});
		}
	}

	/**
	 * Method to handle label click events
	 * @param {MouseEvent} event - Click event.
	 */
	handleLabelClick(event) {
		const {labelDialog, labelDialogProps, labelDialogOptions} = this.props;
		const {data, preview} = this.state;

		// Open label dialog if is specified
		if (labelDialog) {
			// Cancel default label click event that usually sets focus to the related element
			event.preventDefault();

			// Open label dialog
			this.openLabelDialog({component: labelDialog, props: {
				data, 
				preview,
				...labelDialogProps
			}, options: labelDialogOptions});
		}

		// Trigger 'onLabelClick' event
		executeComponentCallback(this.props.onLabelClick, event);
	}


	// Insert value methods ---------------------------------------------------------------------------------------------
	/**
	 * Check if string contains any insert value
	 * @param {string} string - String to check
	 * @return {boolean}
	 */
	containsInsertValue(string) {
		const {insertValueType, insertValueTypeOptions} = this.props;
		switch (insertValueType) {
			case INSERT_VALUE_BUTTON_TYPE.DIALOG:
				/** @type {InsertValueDialogSectionDataObject[]} */
				const sections = getArray(insertValueTypeOptions, 'dialogProps.sections');
				for (let i of sections) if (i.contains(string)) return true;
				return false;

			case INSERT_VALUE_BUTTON_TYPE.DROPDOWN:
				// TODO: dropdown insert value
				return false;

			default:
				return false;
		}
	}


	// Autosuggest methods ----------------------------------------------------------------------------------------------
	/**
	 * Load options for autosuggest in local state
	 *
	 * @param {string} [query=''] - Query string from input.
	 * @return {Promise<string[]>} - Loaded options.
	 */
	async loadAutosuggestOptions(query = '') {
		const {autosuggestMinLength, autosuggestAction} = this.props;

		if (isset(autosuggestAction) && autosuggestMinLength >= 0) {
			const autosuggestOptions = (
				!!query.trim() && query.length >= autosuggestMinLength ?
					await this.executeAbortableAction(autosuggestAction, query) :
					[]
			);
			return this.setState({autosuggestOptions}).then(() => autosuggestOptions);
		}

		return this.setState({autosuggestOptions: []}).then(() => []); 
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * render autosuggest options
	 * @return {JSX.Element}
	 */
	renderSuggestions() {
		const {autosuggestOptions} = this.state;
		
		return (
			<datalist id={`${this.getDomId()}-datalist`}>
				{getArray(autosuggestOptions).map(i => <option key={i} value={i}>{i}</option>)}
			</datalist>
		);
	}

	/**
	 * Render insert button
	 * @return {JSX.Element}
	 */
	renderInsertButton() {
		const {insertValueButtonProps, insertValueType, insertValueTypeOptions, insertValueMethod} = this.props;
		return (
			<InsertValueButton
				buttonProps={{
					displayStyle: BUTTON_STYLE.NONE,
					className: 'input-toolbar-button',
					...insertValueButtonProps
				}}
				insertType={insertValueType}
				insertTypeOptions={insertValueTypeOptions}
				onInsert={
					insertValueMethod === 'insert' ? v => this.insertValue(v) :
					insertValueMethod === 'update' ? v => this.updateValue(v) :
					null
				}
				onDialogClose={() => this.inputRef.focus()}
			/>
		);
	}

	/**
	 * Render clear button
	 * @return {JSX.Element}
	 */
	renderClearButton() {
		return (
			<Button
				className="input-toolbar-button"
				icon="times"
				displayType={BUTTON_DISPLAY_TYPE.NONE}
				onClick={e => {
					this.updateValue('');
					executeComponentCallback(this.props.onClear, e);
				}}
			/>
		);
	}
	
	/**
	 * Render input DOM element
	 * @return {JSX.Element}
	 */
	renderInput() {
		const {
			className, name, placeholder, readOnly, disabled, formControlStyle, type, isFast, errors, autoComplete,
			inputToolbarButtons, showInsertValueButton, insertValueButtonPosition, showClearButton, inputProps
		} = this.props;
		const value = this.getData();
		const inputToolbarButtonsL = inputToolbarButtons.filter(b => b.position === TEXT_INPUT_TOOLBAR_POSITION.LEFT);
		const inputToolbarButtonsR = inputToolbarButtons.filter(b => b.position === TEXT_INPUT_TOOLBAR_POSITION.RIGHT);

		return (
			<div className={`text-input-wrapper ${styles['inputWrapper']}`}>
				{
					inputToolbarButtonsL.length || 
					(
						!readOnly && !disabled && 
						(showInsertValueButton && insertValueButtonPosition === TEXT_INPUT_TOOLBAR_POSITION.LEFT)
					) ?
						<div className={`input-toolbar ${styles['input-toolbar']} position-left ${styles[`position-left`]}`}>
							{inputToolbarButtonsL.map(btnProps => {
								const ButtonComponent = get(btnProps, 'component', Button);
								return (
									<ButtonComponent
										{...omit(btnProps, ['className', 'onClick', 'component'])}
										className={`${getString(btnProps, 'className')} input-toolbar-button`}
										onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
									/>
								);
							})}
							{!readOnly && !disabled && showInsertValueButton ? this.renderInsertButton() : null}
						</div>
						: null
				}
				
				<input type={type}
					id={this.getDomId()}
					className={
						`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className} ` +
						`${styles['input']}`
					}
					name={name}
					value={value}
					onInput={!readOnly && !isFast ? this.handleInputChange : null}
					onChange={!readOnly ? this.handleInputChange : null}
					onBlur={!readOnly && isFast ? this.update : null}
					onFocus={this.handleInputFocus}
					onKeyPress={!readOnly ? this.handleKeyPress : null}
					onKeyDown={!readOnly ? this.handleKeyDown : null}
					onKeyUp={!readOnly ? this.handleInputKeyUp : null}
					onPaste={!readOnly ? this.handleInputPaste : null}
					onClick={this.handleInputClick}
					disabled={disabled}
					placeholder={placeholder}
					readOnly={readOnly}
					autoComplete={autoComplete}
					list={`${this.getDomId()}-datalist`}
					ref={node => { this.inputRef = node; }}
					{...inputProps}
				/>
				{
					errors.length ?
						<Tooltip
							className={styles['errorIcon']}
							tag="span" 
							title={errors.join("<br />")} 
							size="small" 
							position="top-center" 
							arrow={true} 
							interactive={false}
							supportHtml={true}
						>
							<Icon symbol={icon_font_error_symbol} />
						</Tooltip>
						: null
				}

				{
					inputToolbarButtonsR.length || 
					(
						!readOnly && !disabled && 
						(
							showClearButton ||
							(showInsertValueButton && insertValueButtonPosition === TEXT_INPUT_TOOLBAR_POSITION.RIGHT)
						)
					) ?
						<div
							className={`input-toolbar ${styles['input-toolbar']} position-right ${styles[`position-right`]}`}
						>
							{inputToolbarButtonsR.map(btnProps => {
								const ButtonComponent = get(btnProps, 'component', Button);
								return (
									<ButtonComponent
										{...omit(btnProps, ['className', 'onClick', 'component'])}
										className={`${getString(btnProps, 'className')} input-toolbar-button`}
										onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
									/>
								);
							})}
							{!readOnly && !disabled && showInsertValueButton ? this.renderInsertButton() : null}
							{!readOnly && !disabled && showClearButton ? this.renderClearButton() : null}
						</div>
						: null
				}
			</div>
		);
	}

	render() {
		const {
			disabled, formControlStyle, showLabel, labelPosition, labelWidth, labelClass, label, previewAsLabel, 
			onLabelClick, wrapperClassName, labelDialog, autosuggestMinLength, autosuggestAction, 
		} = this.props;
		const {preview} = this.state;
		const shouldRenderSuggestion = (isset(autosuggestAction) && autosuggestMinLength >= 0);

		return (
			showLabel ?
				<div
					className={
						'input-wrapper text-input-component-wrapper ' +
						`${formControlStyle ? 'form-control-wrapper' : ''} ${styles['wrapper']}`+
						(labelPosition === TEXT_INPUT_LABEL_POSITION.LEFT ? ` left-label ${styles['left-label']}` : '') +
						(labelPosition === TEXT_INPUT_LABEL_POSITION.RIGHT ? ` right-label ${styles['right-label']}` : '') +
						(disabled ? ` disabled ${styles['disabled']}` : '') +
						(labelClass ? ` ${labelClass}` : '')
					}
				>
					<label
						htmlFor={this.getDomId()}
						className={
							`text-input-label ${styles['label']}` +
							(
								typeof onLabelClick === 'function' || typeof labelDialog !== 'undefined' ? 
									` clickable ${styles['clickable-label']}` : ''
							)
						}
						style={{width: labelWidth ? (isString(labelWidth) ? labelWidth : `${getString(labelWidth)}px`) : ''}}
						onClick={this.handleLabelClick}
					>
						<div className={`preview-content ${styles['preview']}`}>{previewAsLabel ? preview : label}</div>
					</label>
					{this.renderInput()}
					{shouldRenderSuggestion ? this.renderSuggestions() : null}
				</div>
				:
				<div
					className={
						'input-wrapper text-input-component-wrapper ' +
						`${formControlStyle ? 'form-control-wrapper' : ''} ${styles['wrapper']} ` +
						`${disabled ? ` disabled ${styles['disabled']}` : ''} ${wrapperClassName}`
					}
				>
					{this.renderInput()}
					{shouldRenderSuggestion ? this.renderSuggestions() : null}
				</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TextInput.propTypes = {
	// Input element's id attribute
	id: PropTypes.string,
	// Input element's class attribute
	className: PropTypes.string,
	// Component wrapper element class name
	wrapperClassName: PropTypes.string,
	// Input element's name attribute
	name: PropTypes.string,
	// Input element's value
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// Input element's placeholder
	placeholder: PropTypes.string,
	// Flag specifying if text input should be read only
	readOnly: PropTypes.bool,
	// Flag that determines if the input should be disabled
	disabled: PropTypes.bool,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// DOM input element type 
	type: PropTypes.string,
	// Flag that specifies if input will use the fast mode meaning it will only update ('onChange' event) on blur or 
	// Enter key press.
	isFast: PropTypes.bool,
	// Flag that specifies if component will update (call 'onChange' event) if input changes when input is not in focus
	// @description This is useful when value is inserted into the input by the browser (password generation, autofill, 
	// etc.) and component is working in the 'fast' mode (isFast is true) so it won't trigger the update automatically.
	updateWhenChangedWithoutFocus: PropTypes.bool,

	// Flag that determines if label should be shown
	showLabel: PropTypes.bool,
	// Label position (see TEXT_INPUT_LABEL_POSITIONS const)
	labelPosition: PropTypes.string,
	// Label width string with units (ex: '400px' or '30%') or a number of pixels.
	labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// Label additional class
	labelClass: PropTypes.string,
	// Label text or any other value that can be rendered
	label: PropTypes.any,
	// Flag that determines if label is used as a preview for the main value
	// @description If this is true, label will be a preview of the current value, and it will update on every change 
	// (not just on blur or enter key like the main exported value). Preview will be generated using the 'getPreview' 
	// function prop if specified.
	previewAsLabel: PropTypes.bool,
	// Function that should generate current value preview
	// @description Function will be called with current value as it's only argument, and it should return a preview of 
	// that value. If not specified, current value will be used as preview.
	getPreview: PropTypes.func,
	// Dialog that will be opened when label is clicked
	labelDialog: PropTypes.any,
	// Props sent to the dialog that will be opened when label is clicked
	labelDialogProps: PropTypes.object,
	// Options sent to the dialog that will be opened when label is clicked
	labelDialogOptions: PropTypes.object,
	// Autocomplete input attribute
	autoComplete: PropTypes.string,
	
	// Toolbar buttons that will be shown in the input field
	// @note If custom component is used this should contain props for that component.
	inputToolbarButtons: PropTypes.arrayOf(PropTypes.shape({
		// Position where button will be rendered (left or right)
		position: PropTypes.oneOf(TEXT_INPUT_TOOLBAR_POSITIONS),
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
		// to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,
		// Component to render instead of the Button
		component: PropTypes.elementType,

		// Events
		onClick: PropTypes.func,
	})),
	
	// Error messages
	// @description Input error messages that will be shown in a tooltip of the error icon. Error icon will be rendered
	// only if there are errors.
	errors: PropTypes.arrayOf(PropTypes.string),

	// Flag that determines if insert value button component (InsertValueButton) will be rendered inside the input
	showInsertValueButton: PropTypes.bool,
	// Position of the insert value button
	insertValueButtonPosition: PropTypes.oneOf(TEXT_INPUT_TOOLBAR_POSITIONS),
	// Insert value button props
	insertValueButtonProps: PropTypes.shape({
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
		// to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,

		// Events
		onClick: PropTypes.func,
	}),
	// Insert type
	insertValueType: PropTypes.oneOf(INSERT_VALUE_BUTTON_TYPES),
	// Insert type options
	// @note Options depend on 'insertValueType'.
	insertValueTypeOptions: PropTypes.oneOfType([
		// INSERT_VALUE_BUTTON_TYPE_DIALOG
		PropTypes.shape({
			dialogProps: PropTypes.object,
			dialogOptions: PropTypes.shape({
				id: PropTypes.string,
				className: PropTypes.string,
				closeOnEscape: PropTypes.bool,
				closeOnClickOutside: PropTypes.bool,
				hideCloseBtn: PropTypes.bool,
				maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
			}),
		}),
		// INSERT_VALUE_BUTTON_TYPE_DROPDOWN
		PropTypes.shape({
			// TODO: dropdown insert value
		})
	]),
	// Specify if value should be inserted at the cursor position ('insert') or replaced the current value ('update')
	insertValueMethod: PropTypes.oneOf(['insert', 'update']),
	
	// Flag that determines if clear button will be rendered
	showClearButton: PropTypes.bool,
	// Flag that specifies if input will be cleared when pressing the Esc keyboard button
	// @note This will trigger 'onClear' event.
	clearOnEsc: PropTypes.bool,

	// Other input props
	inputProps: PropTypes.object,
	
	// Action function used to offer autosuggest options for the text input
	// @param {function} [abortCallback=(abortController)=>{}] - Function to abort the IO request.
	// @param {string} [query=''] - Query string.
	// @return {function(*): Promise<string[]>} - Array of string options to suggest.
	autosuggestAction: PropTypes.func,
	// Min. number of characters that will trigger the autosuggest action
	autosuggestMinLength: PropTypes.number,

	// Value change event
	// @description This event will be triggers when input value changes. Changes are optimized for performance and won't
	// trigger on every key press.
	onChange: PropTypes.func, // Arguments: {Event} event - Can be onChange, onBlur or on 'Enter' key press (onKeyPress)
	// Input element input event
	// @description This event will be triggers on any input change (key press, paste, ...).
	// @note Use this event carefully to avoid performance issues.
	onInput: PropTypes.func, // Arguments: {Event} event - onChange
	// Label click event
	// @description This event will be triggers when clicking on an input text label if it exists.
	onLabelClick: PropTypes.func, // Arguments: {Event} event - onClick
	// Input element enter key press event
	onEnterKey: PropTypes.func,
	// Input element paste event
	onPaste: PropTypes.func,
	// Input element click event
	onClick: PropTypes.func,
	// Input element key up event
	onKeyUp: PropTypes.func,
	// Input element key down event
	onKeyDown: PropTypes.func,
	// Input element key press event
	onKeyPress: PropTypes.func,
	// Input element focus event
	onFocus: PropTypes.func,
	// Event triggered when clearing the input either by clicking the clear button if 'showClearButton' is true, or by
	// pressing Esc keyboard button if 'clearOnEsc' is true. It will receive a button click event or a keyboard key down
	// event respectively.
	onClear: PropTypes.func,
};

/**
 * Define component default values for own props
 */
TextInput.defaultProps = {
	id: '',
	className: '',
	wrapperClassName: '',
	name: '',
	value: '',
	placeholder: '',
	readOnly: false,
	disabled: false,
	formControlStyle: true,
	isFast: true,

	showLabel: false,
	labelPosition: TEXT_INPUT_LABEL_POSITION.LEFT,
	labelWidth: 30,
	labelClass: '',
	label: '',
	previewAsLabel: false,
	labelDialogOptions: {},
	type: 'text',
	autoComplete: 'on',
	
	inputToolbarButtons: [],
	
	errors: [],

	showInsertValueButton: false,
	insertValueButtonPosition: TEXT_INPUT_TOOLBAR_POSITION.RIGHT,
	insertValueMethod: 'insert',
	showClearButton: false,
	clearOnEsc: false,
	inputProps: {},

	autosuggestMinLength: 1,
};

export default TextInput;
export * from "./const";
export {executeComponentCallback} from "../../DataComponent";
export {default as ColorPreview} from "./preview/Color";
export {default as PasswordStrengthPreview} from "./preview/PasswordStrength";
export {default as PasswordConfirmPreview} from "./preview/PasswordConfirm";
export {default as DatePreview} from "./preview/Date";
export {default as IconPreview} from "./preview/Icon";