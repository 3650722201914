import "./index.css";

// Import app pages
import * as homePage from "./home";
import * as patientRecordPage from "./patientRecord";
// Management pages
import * as therapistsPage from "./therapists";
import * as physiciansPage from "./physicians";
import * as medicalRecordCategoriesPage from "./medicalRecordCategories";
import * as recommendationsPage from "./recommendations";
import * as officeLocationPage from "./officeLocation";
import * as therapyTypesPage from "./therapyTypes";
import * as diagnosesPage from "./diagnoses";
import * as suppliersPage from "./suppliers";
import * as articleGroupsPage from "./articleGroups";
import * as procurementsPage from "./procurements";
import * as pricesPage from "./prices";
import * as expertReportTypesPage from "./expertReportTypes";
// Search pages
import * as searchAppointmentsPage from "./search/searchAppointments";
import * as searchVisitsPage from "./search/searchVisits";
import * as searchTherapiesPage from "./search/searchTherapies";
import * as searchPaymentsPage from "./search/searchPayments";
import * as searchPatientsPage from "./search/searchPatients";
import * as searchSalesPage from "./search/searchSales";
// Statistics pages
import * as officeLocationStatisticsPage from "./statistics/officeLocationStatistics";
import * as therapistStatisticsPage from "./statistics/therapistStatistics";
import * as otherStatisticsPage from "./statistics/otherStatistics";

import AppSectionComponent from "Core/components/AppSectionComponent";
import * as appConfig from "./config";
import {appHasLogin} from "Core/helpers/login";

class DefaultApp extends AppSectionComponent {
	constructor(props) {
		super(props, appConfig, {
			checkLogin: true,
			autoLogout: appHasLogin(),
			appPages: [
				homePage,
				patientRecordPage,
				therapistsPage,
				physiciansPage,
				medicalRecordCategoriesPage,
				recommendationsPage,
				officeLocationPage,
				therapyTypesPage,
				diagnosesPage,
				suppliersPage,
				articleGroupsPage,
				procurementsPage,
				pricesPage,
				expertReportTypesPage,
				searchAppointmentsPage,
				searchVisitsPage,
				searchTherapiesPage,
				searchPaymentsPage,
				searchPatientsPage,
				searchSalesPage,
				officeLocationStatisticsPage,
				therapistStatisticsPage,
				otherStatisticsPage,
			],
		}, 'default-app');
	}

	render() {
		return this.renderApp();
	}
}

export * from "./config";
export default DefaultApp;