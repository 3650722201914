import styles from "./index.module.css";
import logoLight from "Images/logo_small_light.svg";
import logoDark from "Images/logo_small_dark.svg";

import React from "react";
import BaseComponent from "../../BaseComponent";
import PropTypes from "prop-types";
import {isset} from 'Core/helpers/data';
import {SKIN_MODE} from 'Core/const/global';
import {getSkin} from 'Core/helpers/skin';

class AppName extends BaseComponent {
	constructor(props) {
		super(props, {
			updateOnSkinChange: true,
		});
	}
	
	render() {
		const {showAppLogo, appLogo, showAppName, appName} = this.props;
		
		return (
			<div id={this.getDomId()} className={`app-name-wrapper ${styles['wrapper']}`}>
				{
					showAppLogo ?
						<div className={`logo ${styles['appLogo']} ${showAppName ? styles['withName'] : ''}`}>
							<img 
								src={isset(appLogo) ? appLogo : (getSkin() === SKIN_MODE.DARK ? logoDark : logoLight)}
								alt={isset(appName) ? appName : this.t('title', 'App')}
							/>
						</div>
						: null
				}
				{
					showAppName ?
						<div className={`name ${styles['appName']}`}>
							{isset(appName) ? appName : this.t('title', 'App')}
						</div>
						: null
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AppName.propTypes = {
	// Flag that specifies if app logo will be rendered
	showAppLogo: PropTypes.bool,
	// App logo to render instead of the default one
	// @note If undefined, default app's small logo will be rendered
	appLogo: PropTypes.any,
	// Flag that specifies if app name will be rendered
	showAppName: PropTypes.bool,
	// App name to render instead of the default one
	// @note If undefined, default app's name will be rendered
	appName: PropTypes.string,
};

/**
 * Define component default values for own props
 */
AppName.defaultProps = {
	showAppLogo: true,
	appLogo: undefined,
	showAppName: true,
	appName: undefined,
};

export default AppName;