import React from "react";
import {
	app_id,
	locale_cookie_group,
	sidebar_menu_groups_statuses_storage_field_name,
	sidebar_menu_shrank_cookie_group,
	skin_mode_cookie_group
} from 'Config/app';
import {translate} from "Core/i18n";

/**
 * Cookie policy for sr-Cyrl-RS locale
 * @note This will automatically be used in CookiePolicy component (index.js) if sr-Cyrl-RS is the current locale.
 * There is no need to use this component directly.
 *
 * @param {any} props - Any prop that will be added to the DIV element.
 * @return {JSX.Element}
 * @constructor
 */
function CookiePolicySrCyrlRS(props) {
	return (
		<div {...props}>
			<h2>О колачићима</h2>
			<p>...</p>

			<h2>Спецификација колачића</h2>
			<div className="data-table-component standard">
				<table>
					<thead>
						<tr>
							<th className="column"><div className="content">КАТЕГОРИЈА</div></th>
							<th className="column" width="1"><div className="content">НАЗИВ</div></th>
							<th className="column"><div className="content">ОПИС</div></th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_pauseRequests</div></td>
							<td><div className="content">Колачић који се користи за паузирање системских процеса</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_authToken</div></td>
							<td><div className="content">Колачић који се користи за ауторизацију улогованог корисника</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_authTokenData</div></td>
							<td><div className="content">Колачић који садржи ауторизациони кључ тренутно улогованог корисника</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_refreshToken</div></td>
							<td><div className="content">Колачић који се користи за ауторизацију улогованог корисника</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_refreshingToken</div></td>
							<td><div className="content">Колачић који се користи за ауторизацију улогованог корисника</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_acl</div></td>
							<td><div className="content">Колачић који садржи листу привилегија тренутно улогованог корисника</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_currentUser</div></td>
							<td><div className="content">Колачић који садржи системске податке тренутно улогованог корисника (корисничко име, тип, итд.)</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_userActivity</div></td>
							<td><div className="content">Колачић који садржи системске податке о активности тренутно улогованог корисника</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_cookie_consent_settings</div></td>
							<td><div className="content">Колачић који садржи информацију о томе које колачиће је корисник прихватио</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${locale_cookie_group}`)}</div></td>
							<td><div className="content">{app_id}_locale</div></td>
							<td><div className="content">Колачић који садржи системски код региона на основу којег се одређује језик који ће бити коришћен</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${sidebar_menu_shrank_cookie_group}`)}</div></td>
							<td><div className="content">sidebar_menu_shrank</div></td>
							<td><div className="content">Колачић који одређује величину главног навигационог менија</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:necessary`)}</div></td>
							<td><div className="content">{sidebar_menu_groups_statuses_storage_field_name}</div></td>
							<td><div className="content">Колачић који одређује који елементи главног навигационог менија ће бити отворени</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${skin_mode_cookie_group}`)}</div></td>
							<td><div className="content">skin_mode</div></td>
							<td><div className="content">Колачић који одређује тему (боје) апликације</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${skin_mode_cookie_group}`)}</div></td>
							<td><div className="content">use_system_skin_mode</div></td>
							<td><div className="content">Колачић који одређује тему (боје) апликације</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:functional`)}</div></td>
							<td><div className="content">emoji-mart.frequently</div></td>
							<td><div className="content">Колачић који чува листу најчешће коришћених емотикона како би они били доступни приликом убациванја емотикона у неки садржај</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:functional`)}</div></td>
							<td><div className="content">emoji-mart.last</div></td>
							<td><div className="content">Колачић који чува информацију о последње коришћеном емотикону</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:functional`)}</div></td>
							<td><div className="content">calendar_preferences</div></td>
							<td><div className="content">Колачић који чува подешавања календара на почетној страници</div></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default CookiePolicySrCyrlRS;