import {get, isPlainObject, forOwn, has, cloneDeep} from "lodash";
import {
	io_standard_request_headers,
	io_json_request_headers,
	io_upload_request_headers,
	io_base_urls,
	io_pause_requests_storage_field_name,
	io_pause_requests_check_interval,
	auth_default_auto_token,
	io_download_request_headers,
	io_show_response_status_code_error_messages_first,
	io_form_data_request_headers, 
	auth_api_endpoints, 
	auth_api,
} from "Config/index";
import {REQUEST_TYPE, RESPONSE_DATA_TYPE} from "./const";
import {hasTranslation, translate} from "Core/i18n";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import {getNumber, getString} from "Core/helpers/data";
import {jsonToFormData} from "Core/helpers/data";
import {areTokensRefreshing, refreshTokensWait} from "Core/auth/helper";

/**
 * Low level function for building request headers
 * 
 * @param {array[]|object} headersData - Headers data array or object. If array was passed each array item should be an
 * array with two items: header field name and header field value in that order. If object was passed own property names
 * will be used as header field names and own property values will be used as header field values.
 * @example 
 * 	[
 * 		['Accept', 'application/json, text/javascript'], 
 * 		['Content-Type', 'application/x-www-form-urlencoded']
 * 	]
 * @example
 * 	{
 * 		'Accept': 'application/json, text/javascript', 
 * 		'Content-Type': 'application/x-www-form-urlencoded'
 * 	}
 * 	
 * @returns {Headers} Required headers.
 */
export const buildHeaders = (headersData) => {
	let headers = new Headers();
	if (Array.isArray(headersData)) headersData.forEach(header => { headers.set(...header) });
	else if (isPlainObject(headersData)) forOwn(headersData, name => { headers.set(name, headersData[name]) });
	return headers;
}

/**
 * Low level function for building request
 * @note This function does not handle auth tokens.
 *
 * @param {string} [type=''] - Internal request type (see imported REQUEST_TYPE const).
 * @param {string} [method='post'] - Request method ('post', 'get', ...).
 * @param {any} [data=null] - Data to used for the request body.
 * @param {object} [options={}] - Other request options tha will be sent.
 * @param {Headers} [headers=null] - Request headers.
 * @param {boolean} [isDownload=false] - Flag that specifies if request should be built as a download request (appends 
 * 'io_download_request_headers' from io config value to request headers).
 * @return {object} Request object.
 */
export const buildRequest = (
	type = '', method = 'post', data = null, options = {}, headers = null, isDownload = false
) => {
	let body;
	
	switch (type) {
		case REQUEST_TYPE.STANDARD:
			if (!headers) {
				headers = buildHeaders(
					isDownload ?
						io_standard_request_headers.concat(io_download_request_headers) : 
						io_standard_request_headers
				);
			}
			body = (data ? Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&') : '');
			break;
		case REQUEST_TYPE.JSON:
			if (!headers) {
				headers = buildHeaders(
					isDownload ?
						io_json_request_headers.concat(io_download_request_headers) :
						io_json_request_headers
				);
			}
			body = JSON.stringify(data);
			break;
		case REQUEST_TYPE.FORM_DATA:
			if (!headers) {
				headers = buildHeaders(
					isDownload ?
						io_form_data_request_headers.concat(io_download_request_headers) :
						io_form_data_request_headers
				);
			}
			body = jsonToFormData(data);
			break;
		case REQUEST_TYPE.UPLOAD:
			if (!headers) {
				headers = buildHeaders(
					isDownload ?
						io_upload_request_headers.concat(io_download_request_headers) :
						io_upload_request_headers
				);
			}
			body = jsonToFormData(data);
			break;
		default:
			body = data;
	}
	
	// Create an empty headers if it was not specified
	if (!headers) headers = new Headers();
	
	// HEAD and GET requests cannot have body
	if (method.toLowerCase() === 'head' || method.toLowerCase() === 'get') return {method, headers, ...options};
	else return {method, headers, body, ...options};
};

/**
 * Low-level request processing function
 *
 * @param {string} [type=''] - Internal request type (see imported REQUEST_TYPE const).
 * @param {string} url - Request's URL.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs).
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs).
 * @param {Object} request - Request object already built with 'buildRequest' function.
 * @param {boolean} [autoToken] - If true and there is an auth request processor inside 'processors' argument, 
 * auth token will be retrieved from storage and added to the request.
 * @param {string} [token=''] - If specified and there is an auth request processor inside 'processors' argument but 
 * 'autoToken' is false, this token will be added to the request.
 * @param {string[]} processors - List of all request processors that should be used to process the request.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @return {Promise<Object>} Promise that will resolve with the request object after all request processing has been 
 * done.
 */
export const processRequest = async (
	type = '', url, api = '', endpoint = '', request, autoToken = auth_default_auto_token, token = '', processors = [], 
	captchaAction = ''
) => {
	let result = request;
	if (processors.length > 0) {
		for (let i = 0; i < processors.length; i++) {
			/** @type {Function} */
			const ioRequestProcessor = await import(`../../io/processors/${processors[i]}RequestProcessor`)
				.catch(() => import(`./processors/${processors[i]}RequestProcessor`))
				.then(({default: ioRequestProcessor}) => ioRequestProcessor);

			result = await ioRequestProcessor(type, url, api, endpoint, result, autoToken, token, captchaAction);
		}
	}
	return result;
};

/**
 * Low-level function for actually fetching IO requests and processing responses
 *
 * @param {string} [type=''] - Internal request type (see imported REQUEST_TYPE const).
 * @param {string} url - Request's URL.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs).
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs).
 * @param {Object} request - Request object already built with 'buildRequest' function.
 * @param {boolean} [autoToken] - If true and there is an auth request processor inside 'processors' argument,
 * auth token will be retrieved from storage and added to the request.
 * @param {string} [token=''] - If specified and there is an auth request processor inside 'processors' argument but
 * 'autoToken' is false, this token will be added to the request.
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified, and it cannot be retrieved from the config, response will be return as Blob.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {string[]} [requestProcessors=[]] - List of request processors that should be used to process the request.
 * @param {string[]} [responseProcessors=[]] - List of response processors that should be used to process the response.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument used to abort the fetch request.
 * @return {Promise<Response|any>} Promise that will resolve with the fetch response if there are no response processors
 * or whatever response processors resolve to.
 */
export const fetchAndProcessResponse = async (
	type = '', url, api = '', endpoint = '', request, autoToken = auth_default_auto_token, token = '', responseType, 
	captchaAction = '', requestProcessors = [], responseProcessors = [], abortCallback = () => {}
) => {
	// Process request
	let pRequest = cloneDeep(request);
	if (requestProcessors.length > 0) {
		pRequest = await processRequest(
			type, url, api, endpoint, pRequest, autoToken, token, requestProcessors, captchaAction
		);
	}

	// Fetch and process the request if response processors are defined
	if (responseProcessors.length > 0) {
		// Get all request processors
		let ioResponseProcessors = [];
		for (let i = 0; i < responseProcessors.length; i++) {
			const ioResponseProcessor = await import(`../../io/processors/${responseProcessors[i]}ResponseProcessor`)
				.catch(() => import(`./processors/${responseProcessors[i]}ResponseProcessor`))
				.then(({default: ioResponseProcessor}) => ioResponseProcessor);
			ioResponseProcessors.push(ioResponseProcessor);
		}

		// Run the result promise through each response processor
		return ioResponseProcessors.reduce((chain, ioResponseProcessor) =>
				ioResponseProcessor(
					chain,
					type,
					url,
					api,
					endpoint,
					pRequest,
					autoToken,
					token,
					responseType,
					captchaAction,
					requestProcessors,
					responseProcessors,
					abortCallback
				),
			fetch(url, pRequest)
		);
	}

	// Fetch the request if no response processors defined
	return fetch(url, pRequest);
};

/**
 * Low-level IO request function
 *
 * @param {string} [type=''] - Internal request type (see imported REQUEST_TYPE const).
 * @param {boolean} [isDownload=false] - Flag that specifies if request should be built as a download request (uses
 * 'io_download_request_headers' io config value).
 * @param {string} url - Request's URL.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs).
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs).
 * @param {any} data - Request data.
 * @param {string} method - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true and there is an auth request processor inside 'processors' argument,
 * auth token will be retrieved from storage and added to the request.
 * @param {string} [token=''] - If specified and there is an auth request processor inside 'processors' argument but
 * 'autoToken' is false, this token will be added to the request.
 * @param {object} [options={}] - Other request options tha will be sent.
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {string} [responseType] - Response data type. Default value is retrieved form IO config ("/src/config/io.js").
 * If response type was not specified, and it cannot be retrieved from the config, response will be return as Blob.
 * @param {boolean} [canPause=false] - If true this request could be paused using a global request pause flag.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {string[]} [requestProcessors=[]] - List of all processors that should be used to process the request.
 * @param {string[]} [responseProcessors=[]] - List of all processors that should be used to process the response.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument used to abort the fetch request.
 * @return {Promise<Response|any>} Promise that will resolve with the fetch response if there are no response processors
 * or whatever response processors resolve to.
 */
export const rawRequest = async ({
	type = '', isDownload = false, url, api = '', endpoint = '', data, method, autoToken = auth_default_auto_token, 
	token = '', options = {}, headers = null, responseType, canPause = false, captchaAction = '', requestProcessors = [],
	responseProcessors = [], abortCallback = () => {}
}) => {
	// Generate abort controller and send it through a callback function so that the fetch can be aborted
	const controller = new AbortController();
	const signal = controller.signal;
	if (abortCallback) abortCallback(controller);

	// Build request
	let request = buildRequest(type, method, data, {...options, signal}, headers, isDownload);

	// Wait for tokens to refresh
	// @note While tokens are refreshing no other request is permitted. All other requests will be paused regardless of 
	// the 'canPause' flag value.
	if (areTokensRefreshing()) {
		const refreshTokensApi = (
			getString(auth_api_endpoints.refresh_tokens, 'api') ?
				auth_api_endpoints.refresh_tokens.api :
				auth_api
		);
		// Only wait if request is not the refresh tokens request itself since refreshing tokens flag is set just before 
		// the request is made.
		if (url !== getIOUrl(refreshTokensApi, auth_api_endpoints.refresh_tokens.path)) await refreshTokensWait();
	}
	
	// If requests are paused and this request is pause-able
	if (canPause && areRequestsPaused()) {
		return pauseRequestWait()
			.then(() => fetchAndProcessResponse(
				type, 
				url, 
				api, 
				endpoint, 
				request, 
				autoToken, 
				token, 
				responseType, 
				captchaAction, 
				requestProcessors, 
				responseProcessors, 
				abortCallback
			));
	} else {
		return fetchAndProcessResponse(
			type, 
			url, 
			api, 
			endpoint, 
			request, 
			autoToken, 
			token, 
			responseType, 
			captchaAction, 
			requestProcessors,
			responseProcessors, 
			abortCallback
		);
	}
}

/**
 * Increase pause request counter
 * @note This counter is used by all pause-able requests to pause until it is less than one.
 */
export const pauseRequests = () => {
	const currentPauseCount = getNumber(getStorageValue(io_pause_requests_storage_field_name, STORAGE_TYPE.LOCAL));
	setStorageValue(io_pause_requests_storage_field_name, (currentPauseCount + 1), STORAGE_TYPE.LOCAL);
};

/**
 * Check if requests are paused
 * @note Requests are paused using the request pause counter is less then one.
 * @return {boolean}
 */
export const areRequestsPaused = () => {
	const currentPauseCount = getNumber(getStorageValue(io_pause_requests_storage_field_name, STORAGE_TYPE.LOCAL));
	return (currentPauseCount > 0);
}

/**
 * Decrease pause request counter
 * @note Once pause request counter is less than 1 all pause-able requests will resume execution.
 */
export const resumeRequests = () => {
	const currentPauseCount = getNumber(getStorageValue(io_pause_requests_storage_field_name, STORAGE_TYPE.LOCAL));
	setStorageValue(io_pause_requests_storage_field_name, (currentPauseCount - 1), STORAGE_TYPE.LOCAL);
};

/**
 * Promise that will resolve once paused requests are resumed 
 * @return {Promise<void>}
 */
export const pauseRequestWait = () => new Promise(resolve => {
	const interval = setInterval(() => {
		if (!areRequestsPaused()) {
			clearInterval(interval);
			resolve();
		}
	}, io_pause_requests_check_interval);
});

/**
 * Handles responses received from the server by extracting data from the response
 *
 * @param {Response} response - Response received from the server.
 * @param {object} request - Request params object.
 * @param {string} [responseType=''] - Response data type. If not specified, response will be return as Blob.
 * @returns {Promise<any>} Promise that will resolve with response data if it was successful, or it will be rejected 
 * with an object containing the full response and request as a rejection reason.
 */
export const handleResponse = (response, request = null, responseType= '') => {
	if (get(response, 'ok')) {
		switch (responseType) {
			case RESPONSE_DATA_TYPE.ARRAY_BUFFER:
				return response.arrayBuffer();
			case RESPONSE_DATA_TYPE.BLOB:
				return response.blob();
			case RESPONSE_DATA_TYPE.FORM_DATA:
				return response.formData();
			case RESPONSE_DATA_TYPE.JSON:
				// Add request params object to every response through the __request_params__ param
				return response.json().then(data => {
					if (isPlainObject(data) && !has(data, 'data')) return {...data, "__request_params__": request};
					else return data;
				});
			case RESPONSE_DATA_TYPE.TEXT:
				return response.text();
			default:
				return response.blob();
		}
	} else {
		return Promise.reject({response, request});
	}
};

/**
 * Get standard JSON response error message that is ready for display
 * @note This function should be called after the request has been successfully made (there were no network errors or 
 * other issues with making the request).
 * 
 * @important Translation keys are relative to 'errors.io' section of translation files (see 'src/i18n/translations/').
 * 
 * @param {any} responseData - Response data.
 * @param {string} urlTranslationKey - Full request URL that will be used as a fallback translation key.
 * @param {string} [apiTranslationKey='defaultApi'] - Translation key for the given request's API (see 'io_base_urls' 
 * config). This will be ignored if 'endpointTranslationKey' is empty or undefined.
 * @param {string} [endpointTranslationKey=''] - Translation key of the given request's specific API endpoint.
 * @return {string} Response error message that is ready for display or an empty string if there is no error (response
 * errorCode and errorMessages are empty or success is true).
 */
export const getStandardJsonResponseErrorMessage = (
	responseData, urlTranslationKey, apiTranslationKey = 'defaultApi', endpointTranslationKey = ''
) => {
	let errorMessageToDisplay;
	const errorCode = get(responseData, 'errorCode', '');
	const errorMessage = get(responseData, 'errorMessage', '');
	
	// Get translation path of the given request based on api and endpoint (apiTranslationKey and endpointTranslationKey)
	// if endpoint is available or using the entire request URL if it is not (raw requests).
	const translationPath = (
		endpointTranslationKey ? 
			[(apiTranslationKey ? apiTranslationKey : 'defaultApi'), endpointTranslationKey] : 
			[urlTranslationKey]
	);
	
	if (errorCode || errorMessage) {
		// If there is an error code in the response
		if (errorCode) {
			// Try to get the error code translation
			if (hasTranslation(errorCode, ['errors', 'io', ...translationPath])) {
				errorMessageToDisplay = translate(errorCode, ['errors', 'io', ...translationPath]);
			}
			// Try to get the default error translation for this IO request
			else if (hasTranslation('error', ['errors', 'io', ...translationPath])) {
				errorMessageToDisplay = translate('error', ['errors', 'io', ...translationPath]);
			}
			// Try to get the error message from response
			else if (errorMessage) {
				errorMessageToDisplay = errorMessage;
			}
		}
		// If there is no error code in the response
		else {
			// Try to get the default error translation for this IO request
			if (hasTranslation('error', ['errors', 'io', ...translationPath])) {
				errorMessageToDisplay = translate('error', ['errors', 'io', ...translationPath]);
			}
			// Try to get the error message from response
			else if (errorMessage) {
				errorMessageToDisplay = errorMessage;
			}
		}
	}
	// Try to get the default error translation for this IO request
	else if (hasTranslation('error', ['errors', 'io', ...translationPath])) {
		errorMessageToDisplay = translate('error', ['errors', 'io', ...translationPath]);
	}
	// Unknown error
	else if (get(responseData, 'success') === false) {
		errorMessageToDisplay = translate('Server error!', 'errors.io');
	}
	
	return (errorMessageToDisplay ? errorMessageToDisplay : '');
}

/**
 * Get request error message that is ready for display
 * @note This function should only be called on failed requests (there was a network errors or some other issues with 
 * making the request). It should not be called to handle response errors like 401 or 500 because these are actual 
 * responses and this function handles only requests that do not have a response or the response was aborted.
 * 
 * This function assumes that the request failed, and it will always return an error message.
 *
 * @important Translation keys are relative to 'errors.io' section of translation files (see 'src/i18n/translations/').
 *
 * @param {string} urlTranslationKey - Full request URL that will be used as a fallback translation key.
 * @param {string} [apiTranslationKey='defaultApi'] - Translation key for the given request's API (see 'io_base_urls'
 * config). This will be ignored if 'endpointTranslationKey' is empty or undefined.
 * @param {string} [endpointTranslationKey=''] - Translation key of the given request's specific API endpoint.
 * @return {string} Request error message that is ready for display.
 */
export const getRequestErrorMessage = (
	urlTranslationKey, apiTranslationKey = 'defaultApi', endpointTranslationKey = ''
) => {
	let errorMessageToDisplay;

	// Get translation path of the given request based on api and endpoint (apiTranslationKey and endpointTranslationKey)
	// if endpoint is available or using the entire request URL if it is not (raw requests).
	const translationPath = (
		endpointTranslationKey ? 
			[(apiTranslationKey ? apiTranslationKey : 'defaultApi'), endpointTranslationKey] : 
			[urlTranslationKey]
	);
	
	// Try to get the default error translation for this IO request using the request's API endpoint
	if (hasTranslation('error', ['errors', 'io', ...translationPath])) {
		errorMessageToDisplay = translate('error', ['errors', 'io', ...translationPath]);
	}
	// Unknown error
	else {
		errorMessageToDisplay = translate('Server error!', 'errors.io');
	}
	
	return errorMessageToDisplay;
};

/**
 * Get error message for HTTP response code error that is ready for display
 *
 * @important Translation keys are relative to 'errors.http' section of translation files (see 'src/i18n/translations/')
 * 
 * @param {number|string} statusCode - HTTP response code (e.x. 401, 500, ...).
 * @param {string} [statusText=''] - HTTP response status text (this will depend on the browser implementation).
 * @param {string} urlTranslationKey - Full request URL that will be used as a fallback translation key.
 * @param {string} [apiTranslationKey='defaultApi'] - Translation key for the given request's API (see 'io_base_urls'
 * config). This will be ignored if 'endpointTranslationKey' is empty or undefined.
 * @param {string} [endpointTranslationKey=''] - Translation key of the given request's specific API endpoint.
 * @return {string} HTTP response code error message that is ready for display.
 */
export const getResponseStatusCodeErrorMessage = (
	statusCode, statusText = '', urlTranslationKey, apiTranslationKey = 'defaultApi', endpointTranslationKey = ''
) => {
	let errorMessageToDisplay;
	const statusCodeStr = statusCode.toString();

	let translationPath;
	
	if (!io_show_response_status_code_error_messages_first) {
		// Get translation path of the given request based on api and endpoint (apiTranslationKey and 
		// endpointTranslationKey) if endpoint is available or using the entire request URL if it is not (raw requests).
		translationPath = (
			endpointTranslationKey ? 
				[(apiTranslationKey ? apiTranslationKey : 'defaultApi'), endpointTranslationKey] : 
				[urlTranslationKey]
		);
	}

	// Try to get the default error translation for this IO request using the request's API endpoint
	if (translationPath && hasTranslation('error', ['errors', 'io', ...translationPath])) {
		errorMessageToDisplay = translate('error', ['errors', 'io', ...translationPath]);
	}
	
	// Try to get the error translation by the status code
	else if (hasTranslation(statusCodeStr, ['errors', 'http'])) {
		errorMessageToDisplay = translate(statusCodeStr, ['errors', 'http']);
	}
	// Try to get the error translation by status text
	else if (hasTranslation(statusText, ['errors', 'http'])) {
		errorMessageToDisplay = translate(statusText, ['errors', 'http']);
	}
	// Unknown error 
	else {
		errorMessageToDisplay = translate('error', ['errors', 'http']);
	}
	
	return errorMessageToDisplay;
};

/**
 * Returns base URL for IO ('/src/config/io.js' file) that is guaranteed to have a forward slash ('/') at the end
 *
 * @param {string} [api='defaultApi'] - API to use (key of the 'io_base_urls' io config object).
 * @return {string} Base URL for IO.
 */
export const getIOBaseUrl = (api = 'defaultApi') => {
	if (!api) api = 'defaultApi';
	const apiBaseUrl = get(io_base_urls, api, '');
	return apiBaseUrl.endsWith('/') ? apiBaseUrl : `${apiBaseUrl}/`;
}

/**
 * Returns a full IO path with base URL
 * 
 * @param {string} [api='defaultApi'] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} [path=''] - Path relative to the IO base URL. If empty, IO base URL will be returned.
 * @return {string} Full IO path with base url.
 */
export const getIOUrl = (api = 'defaultApi', path = '') => getIOBaseUrl(api) + path;

/**
 * Extracts the file name from a Content-Disposition response header.
 * Supports both standard 'filename' and UTF-8 encoded 'filename*' attributes.
 *
 * @param {string} contentDisposition - The Content-Disposition header value.
 * @returns {string|null} The extracted file name, or null if not found.
 *
 * Examples:
 * - Input: 'attachment; filename="example.txt"'
 *   Output: 'example.txt'
 * - Input: 'attachment; filename*=UTF-8''%E4%BD%A0%E5%A5%BD.txt; filename="fallback.txt"'
 *   Output: '你好.txt'
 * - Input: null or undefined
 *   Output: null
 */
export const extractFileNameFromContentDisposition = contentDisposition => {
	if (!contentDisposition) return null;

	// Check for UTF-8 filename* first
	const utf8Match = contentDisposition.match(/filename\*=(?:UTF-8'')?([^;]+)/i);
	if (utf8Match) {
		try {
			return decodeURIComponent(utf8Match[1].trim().replace(/["']/g, ""));
		} catch (error) {
			console.error("Error decoding UTF-8 filename:", error);
		}
	}

	// Fallback to standard filename
	const standardMatch = contentDisposition.match(/filename=([^;]+)/i);
	if (standardMatch) {
		return standardMatch[1].trim().replace(/["']/g, "");
	}

	// No filename found
	return null;
}