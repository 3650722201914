import cookieImg from "Images/cookie_settings_img.png";
import styles from "./index.module.css";
import "./default.style.css";

import React from "react";
import DialogDataComponent from "Core/components/DialogDataComponent";
import {get, cloneDeep, findIndex, values, concat} from "lodash";
import CookieConsent from "../../index";
import {getArray, getBool, getString} from 'Core/helpers/data';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import PropTypes from "prop-types";
import ToggleInput from "Core/components/input/ToggleInput";
import {ltrimString} from "Core/helpers/string";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import Html from "Core/components/display/Html";
import {cookie_consent_enabled} from "Config/app";
import Label from "Core/components/display/Label";
import CookiePolicy from "../../../../../dataProtection/policies/CookiePolicy";
import {getCookieConsentInstance} from "Core/dataProtection/cookieConsent/helper";
import {deleteStorageKey} from 'Core/storage';

class CookieSettings extends DialogDataComponent {
	constructor(props) {
		super(props, {
			/**
			 * Array of cookie groups
			 * @type {Object<string, {cookie: CookieData, accepted: boolean}[]>}
			 */
			data: cloneDeep(new CookieConsent().loadGroupedCookieSettings()),

			/**
			 * GUIID of the current tab
			 * @type {string}
			 */
			currentTab: 'cookie_policy',
		}, {
			domPrefix: 'cookie-settings-dialog-component',
			translationPath: 'CookieSettings',
			disableLoad: true,
		});
		
		// Action methods
		this.acceptAllCookiesAndSave = this.acceptAllCookiesAndSave.bind(this);
		this.acceptCookieGroup = this.acceptCookieGroup.bind(this);
		this.rejectCookieGroup = this.rejectCookieGroup.bind(this);
		this.save = this.save.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
		this.renderCookiePolicyTab = this.renderCookiePolicyTab.bind(this);
		this.renderGroupTab = this.renderGroupTab.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();

		// Add 'cookie-settings-dialog-visible' class to body
		document.body.classList.add('cookie-settings-dialog-visible');
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		
		// Remove 'cookie-settings-dialog-visible' class from body
		 document.body.classList.remove('cookie-settings-dialog-visible');
	}

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Accept all cookies and save cookie consent settings
	 */
	acceptAllCookiesAndSave() {
		getCookieConsentInstance().acceptAll();
		this.close();
	}

	/**
	 * Save cookie consent settings
	 */
	save() {
		getCookieConsentInstance().save({
			showNotice: false,
			cookies: concat(...values(this.getData()))
		});

		// Delete all cookies that are not accepted
		getArray(getCookieConsentInstance().loadSettings()).forEach(cookie => {
			if (!getBool(cookie, 'accepted')) {
				deleteStorageKey(getString(cookie, 'cookie.name'), getString(cookie, 'cookie.storage'));
			}
		});
		
		this.close();
	}
	
	/**
	 * Select a group of cookies as accepted
	 * @note This method does not automatically save cookie settings. Call the 'save' method to save cookie consent 
	 * settings.
	 * @param {string} groupName - Cookie group name.
	 */
	acceptCookieGroup(groupName) {
		const updatedCookies = cloneDeep(getArray(this.getValue(groupName))).map(cookie => ({...cookie, accepted: true}));
		this.setValue(groupName, updatedCookies).then();
	}

	/**
	 * Select a group of cookies as rejected
	 * @note This method does not automatically save cookie settings. Call the 'save' method to save cookie consent 
	 * settings.
	 * @param {string} groupName - Cookie group name. 'necessary' group will be ignored because necessary cookies cannot 
	 * be rejected.
	 */
	rejectCookieGroup(groupName) {
		if (groupName !== 'necessary') {
			const updatedCookies = cloneDeep(
				getArray(this.getValue(groupName))).map(cookie => ({...cookie, accepted: false})
			);
			this.setValue(groupName, updatedCookies).then();
		}
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render action buttons
	 * @return {JSX.Element}
	 */
	renderActions() {
		return (
			<div className={`tab-actions`}>
				<Button 
					displayStyle={BUTTON_STYLE.ACTION} 
					label={this.t('accept_all')}
					onClick={this.acceptAllCookiesAndSave}
				/>
				<Button 
					displayStyle={BUTTON_STYLE.ACTION} 
					label={this.t('save_settings')}
					onClick={() => this.save()}
				/>
			</div>
		);
	}
	
	/**
	 * Render cookie policy tab
	 * @return {JSX.Element}
	 */
	renderCookiePolicyTab() {
		return (
			<section>
				<div className={`tab-title`}>{this.t('title', p => `${p}.tab.cookie_policy`)}</div>
				<CookiePolicy className='tab-content' />
				{this.renderActions()}
			</section>
		);
	}
	
	/**
	 * Render cookie group tab
	 * @param {string} groupName - Cookie group name.
	 */
	renderGroupTab(groupName) {
		/** @type {{cookie: CookieData, accepted: boolean}[]} */
		const group = get(this.getData(), groupName);
		
		// Don't render the section if group with the specified name does not exist
		if (!group) return null;
		
		// Check if all cookies in this group are accepted by the user
		const isGroupAccepted = (group && findIndex(group, {accepted: false}) === -1);
		
		return (
			<section>
				<div className={`tab-title`}>{this.t('title', p => `${p}.tab.@cookieGroup:${groupName}`)}</div>
				<div className={`tab-content`}>
					<Html content={this.t('content', p => `${p}.tab.@cookieGroup:${groupName}`)} />
					<FormWrapper>
						<FormField
							className={`toggle-cookie-group`}
							labelClassName={`no-select ${isGroupAccepted ? 'on' : 'off'}`}
							label={this.t((isGroupAccepted ? 'On' : 'Off'))}
						>
							<ToggleInput
								key={groupName}
								styleName="action"
								checked={isGroupAccepted}
								disabled={groupName === 'necessary'}
								onChange={e => {
									if (e.target.checked) this.acceptCookieGroup(groupName);
									else this.rejectCookieGroup(groupName);
								}}
							/>
						</FormField>
					</FormWrapper>
				</div>
				{this.renderActions()}
			</section>
		);
	}

	/**
	 * Render tab
	 * @param {'cookie_policy'|string} tabName - Tab name.
	 * @return {JSX.Element|null|*}
	 */
	renderTab(tabName) {
		if (tabName.startsWith('@cookieGroup:')) {
			return this.renderGroupTab(ltrimString(tabName, '@cookieGroup:'));
		} else if (tabName === 'cookie_policy') {
			return this.renderCookiePolicyTab();
		} else {
			return null;
		}
	}
	
	render() {
		const {styleName, className} = this.props;
		const {currentTab} = this.state;
		const data = this.getData();
		
		// Don't render cookie settings dialog if cookie consent is not enabled in app config
		if (!cookie_consent_enabled) return null;
		
		return this.renderDialogWithCustomButtons('', (
			<div 
				id={this.getDomId()} 
				className={`${this.getOption('domPrefix')} ${styles['wrapper']} ${styleName}-style ${className}`}
			>
				<nav>
					{cookieImg ? <img className={`cookie-img`} src={cookieImg} alt="" /> : null}

					<Button
						className={(currentTab === 'cookie_policy' ? 'current' : '')}
						icon={this.t('icon', p => `${p}.tab.cookie_policy`)}
						onClick={() => this.setState({currentTab: 'cookie_policy'})}
						displayType={BUTTON_DISPLAY_TYPE.SOLID}
						displayStyle={BUTTON_STYLE.SUBTLE}
					>
						<Label element="span" content={this.t('nav_link', p => `${p}.tab.cookie_policy`)} />
					</Button>
					{Object.keys(data).map((groupName, idx) => (
						<Button 
							key={idx}
							className={(`@cookieGroup:${groupName}` === currentTab ? 'current' : '')}
							icon={this.t('icon', p => `${p}.tab.@cookieGroup:${groupName}`)}
							onClick={() => this.setState({currentTab: `@cookieGroup:${groupName}`})}
							displayType={BUTTON_DISPLAY_TYPE.SOLID}
							displayStyle={BUTTON_STYLE.SUBTLE}
						>
							<Label element="span" content={this.t('nav_link', p => `${p}.tab.@cookieGroup:${groupName}`)} />
						</Button>
					))}
				</nav>

				{this.renderTab(currentTab)}
			</div>
		));
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CookieSettings.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
};

/**
 * Define component default values for own props
 */
CookieSettings.defaultProps = {
	styleName: 'default',
	id: '',
	className: '',
};

export default CookieSettings;