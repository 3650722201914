import {
	ioJsonDeleteItemAction,
	ioJsonFetchAction,
	ioJsonFetchItemAction,
	ioJsonSaveAction
} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {getArray, isset} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";

/**
 * Reset expert report types list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetExpertReportTypesListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch expert report types list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchExpertReportTypesListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/expert-report-type/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy ? sortBy : null,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load expert report types list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadExpertReportTypesListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchExpertReportTypesListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setExpertReportTypesListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Set expert report types list filter in Redux store
 *
 * @param {Object} filter - Filter to set.
 * @return {(function(*): void)|*}
 */
export const setExpertReportTypesFilterAction = filter => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setExpertReportTypesListFilter(filter));
};

/**
 * Clear expert report types list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearExpertReportTypesListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearExpertReportTypesListData());
}

/**
 * Fetch expert report types item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the expert report types item to fetch.
 * @return {function(*=): Promise<ExpertReportTypesItemDataObject|undefined>}
 */
export const fetchExpertReportTypesItemAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/expert-report-type/fetch-by-id',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isSuccessful(responseData) ? itemDataMap.input(responseData?.data) : undefined);
};

/**
 * Load expert report types item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the expert report types item to load.
 * @return {function(*=): Promise<ExpertReportTypesItemDataObject|undefined>}
 */
export const loadExpertReportTypesItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchExpertReportTypesItemAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setExpertReportTypesItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear expert report types item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearExpertReportTypesItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearExpertReportTypesItem());
};

/**
 * Create expert report types item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {ExpertReportTypesItemDataObject} item - Expert report types item to save.
 * @return {function(*=): Promise<ExpertReportTypesItemDataObject>} Promise that will resolve with the created item
 * received from IO or undefined if creation failed.
 */
export const createExpertReportTypesItemAction = (abortCallback, item) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/expert-report-type/create',
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined);
};

/**
 * Update expert report types item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the expert report types item to update.
 * @param {ExpertReportTypesItemDataObject} item - Expert report types item to save.
 * @return {function(*=): Promise<ExpertReportTypesItemDataObject>} Promise that will resolve with the updated item
 * received from IO or undefined if updating failed.
 */
export const updateExpertReportTypesItemAction = (abortCallback, id, item) => dispatch => {
	const loading = showLoading('#item-popup');

	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/expert-report-type/update-by-id',
		{
			id,
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setExpertReportTypesItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setExpertReportTypesItem(updatedItem));
			return updatedItem;
		});
};

/**
 * Delete expert report types item action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - ID of the expert report types item that will be deleted.
 * @return {function(*): Promise<IoJsonResponseObject>}
 */
export const deleteExpertReportTypesItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/expert-report-type/delete-by-ids',
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
};