import "./index.css";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "./actions";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import ScheduleCalendar from "Components/advanced/ScheduleCalendar";
import {CALENDAR_VIEW} from "Components/advanced/ScheduleCalendar/const";
import Label from "Core/components/display/Label";
import {calendar_max_time, calendar_min_time, calendar_min_time_hours} from "Config/app";
import {getBool, getString, isset, trimArray} from "Core/helpers/data";
import AppointmentDialog from "Components/dialogs/AppointmentDialog";
import {APPOINTMENT_PATIENT_TYPE} from "Const/patient";
import {get} from "lodash";
import {routerPath as patientRecordRouterPath} from "Pages/apps/default/patientRecord";
import {Link} from "react-router-dom";
import Icon from "Core/components/display/Icon";
import AnonymousAppointmentDialog from "Components/dialogs/AnonymousAppointmentDialog";
import AppointmentPatientTypeDialog from "Components/dialogs/AppointmentPatientTypeDialog";
import {camelToSnake} from "Core/helpers/string";
import {addHours} from "date-fns";
import VisitDialog from "Components/dialogs/VisitDialog";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
});

class DefaultAppHomePage extends PageComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'default-app-home-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			renderTitle: false,
		}, 'page_title');

		// Initiate component's state
		this.state = {
			/** @type {CalendarItemDataObject[]|undefined} */
			calendarData: null,
		};
		
		// Data loading methods
		this.loadCalendarData = this.loadCalendarData.bind(this);

		// Dialog methods
		this.openRegularAppointmentDialog = this.openRegularAppointmentDialog.bind(this);
		this.openAnonymousAppointmentDialog = this.openAnonymousAppointmentDialog.bind(this);
		this.openAppointmentPatientTypeDialog = this.openAppointmentPatientTypeDialog.bind(this);
		this.openCreateAppointmentDialog = this.openCreateAppointmentDialog.bind(this);
		this.openCreateAnonymousAppointmentDialog = this.openCreateAnonymousAppointmentDialog.bind(this);
		this.openUsedAppointmentDialog = this.openUsedAppointmentDialog.bind(this);
		
		// Action methods
		this.handleCalendarItemClick = this.handleCalendarItemClick.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }
	
	
	// Data loading methods ---------------------------------------------------------------------------------------------
	/**
	 * Load calendar data
	 * 
	 * @param {Date} startDate - Calendar start date.
	 * @param {Date} endDate - Calendar end date.
	 * @param {?boolean} [findUnused] - Flag that specifies if unused (future) appointments should be included fetched.
	 * @param {?boolean} [findUsed] - Flag that specifies if used (past) appointments should be included fetched.
	 * @return {Promise<*>}
	 */
	loadCalendarData(startDate, endDate, findUnused, findUsed) {
		const {fetchCalendarDataAction} = this.props;
		
		return this.executeAbortableAction(fetchCalendarDataAction, startDate, endDate, findUnused, findUsed)
			.then(calendarData => this.setState({calendarData}));
	}
	
	
	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open regular appointment dialog
	 * 
	 * @param {AppointmentUpdateDataObject} appointmentData
	 * @param {PatientDataObject} patientData
	 * @param {Object} event - The associated FullCalendar Event Object.
	 */
	openRegularAppointmentDialog(appointmentData, patientData, event) {
		const {openDialogAction} = this.props;
		const backgroundColor = (
			getString(event, 'backgroundColor') ? getString(event, 'backgroundColor') : 'var(--fc-event-bg-color)'
		);
		const textColor = (
			getString(event, 'textColor') ? getString(event, 'textColor') : 'var(--fc-event-text-color)'
		);

		const dialogGUIID = openDialogAction('', AppointmentDialog, {
			isNew: false,
			data: appointmentData,
			loadPatientRecordOnSave: false,
			showHelp: true,
			patientInfo: (
				!!patientData ?
					<Link
						className="notice bold patient-link-notice" 
						to={`${patientRecordRouterPath}/item/${patientData.id}`}
						style={{backgroundColor: backgroundColor, color: textColor}}
					>
						<Icon key="icon" symbol="user" style={{color: textColor}}/>
						<Label
							key="label"
							element="span"
							content={
								`${patientData?.firstName}${patientData?.middleName ?
									` (${patientData?.middleName})` :
									``
								} ` +
								`${patientData?.lastName}`
							}
							style={{color: textColor}}
						/>
					</Link>
				: null
			),
			patientId: getString(patientData, 'id'),
			therapySelect: true,
			onSave: () => {
				// Reload homepage calendar data after new therapy has been created
				const reloadScheduleCalendarEvent = new CustomEvent('onReloadScheduleCalendar');
				window.dispatchEvent(reloadScheduleCalendarEvent);
			},
			onDelete: () => {
				// Reload homepage calendar data after new therapy has been created
				const reloadScheduleCalendarEvent = new CustomEvent('onReloadScheduleCalendar');
				window.dispatchEvent(reloadScheduleCalendarEvent);
			}
		}, {
			id: 'update-regular-appointment-dialog',
			className: 'bordered-title update-appointment-dialog',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800,
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open anonymous appointment dialog
	 * @param {AnonymousAppointmentDataObject} appointmentData
	 */
	openAnonymousAppointmentDialog(appointmentData) {
		const {openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', AnonymousAppointmentDialog, {
			isNew: false,
			data: appointmentData,
			onSave: () => {
				// Reload homepage calendar data after new therapy has been created
				const reloadScheduleCalendarEvent = new CustomEvent('onReloadScheduleCalendar');
				window.dispatchEvent(reloadScheduleCalendarEvent);
			},
			onDelete: () => {
				// Reload homepage calendar data after new therapy has been created
				const reloadScheduleCalendarEvent = new CustomEvent('onReloadScheduleCalendar');
				window.dispatchEvent(reloadScheduleCalendarEvent);
			}
		}, {
			id: 'update-anonymous-appointment-dialog',
			className: 'bordered-title update-appointment-dialog',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open dialog for choosing patient type before creating a new appointment
	 *
	 * @param {Date} startDate - Appointment start date to use for initial date and time values. 
	 * @param {Date} endDate - Appointment end date to use for initial date and time values.
	 */
	openAppointmentPatientTypeDialog(startDate, endDate) {
		const {openDialogAction, closeDialogAction} = this.props;
		
		const dialogGUIID = openDialogAction('', AppointmentPatientTypeDialog, {
			onSelectUserType: (dialogGUIID, type, selectedPatient) => {
				closeDialogAction(dialogGUIID);

				switch (type) {
					case APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT:
						this.openCreateAppointmentDialog(selectedPatient, startDate, endDate);
						break;
					case APPOINTMENT_PATIENT_TYPE.ANONYMOUS_PATIENT:
						this.openCreateAnonymousAppointmentDialog(startDate, endDate);
						break;
					// no default
				}
			},
		}, {
			id: 'appointment-type-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: false,
			maxWidth: 800,
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open dialog to create an appointment for an existing patient
	 * 
	 * @param {PatientSelectOptionDataObject} selectedPatient
	 * @param {Date} startDate - Appointment start date to use for initial date and time values.
	 * @param {Date} endDate - Appointment end date to use for initial date and time values.
	 */
	openCreateAppointmentDialog(selectedPatient, startDate, endDate) {
		const {openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', AppointmentDialog, {
			isNew: true,
			startDate,
			endDate,
			loadPatientRecordOnSave: false,
			title: this.t('titleSelectPatient', 'AppointmentPatientTypeDialog'),
			patientInfo: (
				<Label
					element="div"
					elementProps={{className: 'notice success bold'}}
					icon="user"
					content={
						`${selectedPatient?.firstName}${selectedPatient?.middleName ?
							` (${selectedPatient?.middleName})` :
							``
						} ` +
						`${selectedPatient?.lastName}`
					}
				/>
			),
			patientId: getString(selectedPatient, 'id'),
			therapySelect: true,
			onSave: () => {
				// Reload homepage calendar data after new therapy has been created
				const reloadScheduleCalendarEvent = new CustomEvent('onReloadScheduleCalendar');
				window.dispatchEvent(reloadScheduleCalendarEvent);
			}
		}, {
			id: 'create-appointment-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800,
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open dialog to create an appointment for an anonymous patient
	 *
	 * @param {Date} startDate - Appointment start date to use for initial date and time values.
	 * @param {Date} endDate - Appointment end date to use for initial date and time values.
	 */
	openCreateAnonymousAppointmentDialog(startDate, endDate) {
		const {openDialogAction} = this.props;
		const dialogGUIID = openDialogAction('', AnonymousAppointmentDialog, {
			isNew: true,
			startDate,
			endDate,
			onSave: () => {
				// Reload homepage calendar data after new therapy has been created
				const reloadScheduleCalendarEvent = new CustomEvent('onReloadScheduleCalendar');
				window.dispatchEvent(reloadScheduleCalendarEvent);
			}
		}, {
			id: 'create-anonymous-appointment-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 600
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open dialog for the used appointment
	 * @param {string} id - DB ID of the used appointment.
	 * @param {string} patientId - DB ID of the patient associated with the used appointment.
	 */
	openUsedAppointmentDialog(id, patientId) {
		const {openDialogAction} = this.props;
		
		const dialogGUIID = openDialogAction('', VisitDialog, {
			isNew: false,
			id,
			patientId,
			onSave: () => {
				// Reload homepage calendar data after used appointment is saved
				const reloadScheduleCalendarEvent = new CustomEvent('onReloadScheduleCalendar');
				window.dispatchEvent(reloadScheduleCalendarEvent);
			},
		}, {
			id: 'edit-visit-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * handle clicking on any calendar appointment
	 * 
	 * @param {Object} event - The associated FullCalendar Event Object.
	 * @param {Element} el - The HTML element for this event.
	 * @param {MouseEvent} jsEvent - The native JavaScript event with low-level information such as click coordinates.
	 * @param {Object} view - The current FullCalendar View Object.
	 */
	handleCalendarItemClick({event, el, jsEvent, view}) {
		const {fetchAppointmentAction} = this.props;
		const patientType = getString(event, 'extendedProps.patientType');
		const id = getString(event, 'id');
		const used = getBool(event, 'extendedProps.used');
		
		if (used) {
			const visitId = getString(event, 'extendedProps.visitId');
			const patientId = getString(event, 'extendedProps.patientId');
			this.openUsedAppointmentDialog(visitId, patientId);
		} else {
			this.executeAbortableAction(fetchAppointmentAction, patientType, id)
				.then(
					/**
					 * @param {{
					 *    patientType: AppointmentPatientType,
					 *    patientData: ?PatientDataObject,
					 *    appointmentData: AppointmentUpdateDataObject|AnonymousAppointmentDataObject
					 * }|undefined} data
					 */
					data => {
						if (isset(data)) {
							if (getString(data, 'patientType') === APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT) {
								this.openRegularAppointmentDialog(get(data, 'appointmentData'), get(data, 'patientData'), event);
							} else if (getString(data, 'patientType') === APPOINTMENT_PATIENT_TYPE.ANONYMOUS_PATIENT) {
								this.openAnonymousAppointmentDialog(get(data, 'appointmentData'));
							}
						}
					}
				);
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		const {calendarData} = this.state;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true}/>
						</div>
						: null
				}

				<ScheduleCalendar
					views={[
						CALENDAR_VIEW.DAY_GRID_MONTH, 
						CALENDAR_VIEW.TIME_GRID_WEEK, 
						CALENDAR_VIEW.TIME_GRID_DAY, 
						CALENDAR_VIEW.LIST_DAY
					]}
					defaultView={CALENDAR_VIEW.TIME_GRID_DAY}
					calendarProps={{
						slotMinTime: calendar_min_time,
						slotMaxTime: calendar_max_time,
						dateClick: info => {
							const viewType = camelToSnake(getString(info, 'view.type'));
							const startDate = (
								viewType === CALENDAR_VIEW.DAY_GRID_MONTH ?
									// Set start date's hour to be the min tim hours if on month calendar view
									new Date(info.date.setHours(calendar_min_time_hours, 0, 0, 0)) :
									new Date(info.date)
							);
							const endDate = addHours(startDate, 1);
							
							this.openAppointmentPatientTypeDialog(startDate, endDate);
						},
					}}
					data={calendarData}
					onItemClick={this.handleCalendarItemClick}
					onChange={this.loadCalendarData}
				/>
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(DefaultAppHomePage);