import "./index.css";

import React from "react";
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {AppointmentCreateDataObject} from 'DataObjects/appointment';
import DataValueValidation from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {getArray, isset, trimArray} from 'Core/helpers/data';
import * as actions from "./actions";
import {getGlobalActions} from 'Core/helpers/redux';
import DateInput from 'Core/components/input/DateInput';
import {getDate} from 'Core/helpers/datetime';
import TextareaInput from 'Core/components/input/TextareaInput';
import OfficeLocationSelectInput from 'Components/input/OfficeLocationSelectInput';
import TherapistSelectInput from 'Components/input/TherapistSelectInput';
import NumberInput from "Core/components/input/NumberInput";
import {NUMBER_INPUT_USE_APP_LOCALE} from "Core/components/input/NumberInput/const";
import {clone, without} from "lodash";
import CheckboxInput from "Core/components/input/CheckboxInput";
import {DAYS_OF_WEEK} from "Const/dayOfWeek";
import {
	fetchPatientTherapyAction,
	loadPatientTherapyAction
} from "Components/advanced/PatientRecord/components/Therapy/actions";
import {loadPatientRecordAction} from "Components/advanced/PatientRecord/actions";
import PatientTherapySelectInput from "Components/input/PatientTherapySelectInput";
import {SELECT_INPUT_TOOLBAR_POSITION} from "Core/components/input/SelectInput/const";
import {
	calendar_min_time_hours,
	icon_font_close_symbol,
	icon_font_create_symbol, icon_font_delete_symbol,
	icon_font_edit_symbol,
	icon_font_help_circle_symbol, icon_font_save_symbol
} from "Config/app";
import {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import TherapyDialog from "Components/dialogs/TherapyDialog";
import Accordion from "Components/display/Accordion";
import Label from "Core/components/display/Label";
import {scrollToElement} from "Core/helpers/dom";
import {ASYNC_SELECT_INPUT_TOOLBAR_POSITION} from "Core/components/input/SelectAsyncInput/const";
import {hideLoading} from "Core/helpers/loading";
import {isMacintosh} from "Core/helpers/system";
import AppointmentVisitDialog from "Components/dialogs/AppointmentVisitDialog";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import DayTimeInput from "Components/input/DayTimeInput";
import {isBefore} from "date-fns";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({
	...actions, loadPatientTherapyAction, loadPatientRecordAction, fetchPatientTherapyAction
});

class AppointmentDialog extends DialogDataComponent {
	therapySelectRef = null;
	
	constructor(props) {
		super(props, {
			data:
				props.isNew ?
					new AppointmentCreateDataObject(
						props.patientId,
						props.therapyId,
						(isset(props.startDate) ? new Date(props.startDate) : new Date()),
						(
							isset(props.startDate) ?
								new Date(props.startDate) :
								getDate(new Date().setHours(calendar_min_time_hours, 0, 0, 0))
						),
						(
							isset(props.endDate) ?
								new Date(props.endDate) :
								getDate(new Date().setHours(calendar_min_time_hours + 1, 0, 0, 0))
						),
					)
					:
					null,
		}, {
			translationPath: 'AppointmentDialog',
			enableLoadOnDataPropChange: true,
			disableLoad: props.isNew,
		});
		
		// Action methods
		this.openCreateVisitDialog = this.openCreateVisitDialog.bind(this);

		// Dialog methods
		this.openCreateTherapyDialog = this.openCreateTherapyDialog.bind(this);
		this.openEditTherapyDialog = this.openEditTherapyDialog.bind(this);
	}

	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const {isNew} = this.props;
		const dataValidation = new DataValueValidation();
		/** @type {AppointmentCreateDataObject} */
		const dataToValidate = this.getData();

		dataValidation.addRule('date', 'required', 'date');
		dataValidation.addRule('timeFrom', 'required');
		dataValidation.addRule('timeTo', 'required');
		if (isNew) dataValidation.addRule('appointmentNumber', 'required', 'integer');
		dataValidation.addRule('officeLocationId', 'required');

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	async save() {
		const {
			isNew, patientId, therapyId, loadPatientRecordOnSave, dialogGUIID, createAppointmentAction, 
			updateAppointmentAction, addSuccessMessageAction, loadPatientRecordAction, loadPatientTherapyAction
		} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const appointmentId = (
				isNew ?
					await this.executeAbortableAction(createAppointmentAction, data) :
					await this.executeAbortableAction(updateAppointmentAction, data.id, data, false)
			);

			if (!!appointmentId) {
				// Show success message
				if (isNew) addSuccessMessageAction(this.t('create_success_msg'));
				else addSuccessMessageAction(this.t('update_success_msg'));

				if (loadPatientRecordOnSave) {
					if (isNew) {
						// Reload patient record to update global statistics
						await this.executeAbortableAction(loadPatientRecordAction, patientId).then();
						// Reload patient therapy to update statistics
						if (!!therapyId && therapyId !== '*') {
							await this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section');
						}
					}
				}

				this.close();
				
				// Trigger component's 'onSave' event if appointment was saved successfully saved
				executeComponentCallback(this.props.onSave, appointmentId, dialogGUIID);
			}
		}
	}
	
	/**
	 * Open the dialog for logging a visit for this appointment
	 */
	openCreateVisitDialog() {
		const {
			patientId, therapyId, loadPatientRecordOnSave, loadPatientRecordAction, loadPatientTherapyAction, 
			openDialogAction
		} = this.props;
		
		const dialogGUIID = openDialogAction('', AppointmentVisitDialog, {
			patientId,
			appointment: this.getData(),
			onSave: async () => {
				if (loadPatientRecordOnSave) {
					// Reload patient record to update global statistics
					await this.executeAbortableAction(loadPatientRecordAction, patientId).then();
					// Reload patient therapy to update statistics
					if (!!therapyId && therapyId !== '*') {
						await this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section');
					}
				}
				
				this.close();

				// Trigger component's 'onSave' event if appointment was saved successfully saved
				executeComponentCallback(this.props.onSave, null, dialogGUIID);
			}
		}, {
			id: 'appointment-visit-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 600
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	
	/**
	 * Open the dialog to create a new therapy for the selected patient
	 */
	openCreateTherapyDialog() {
		const {patientId, openDialogAction, showSuccessMessageAction} = this.props;

		const dialogGUIID = openDialogAction('', TherapyDialog, {
			isNew: true,
			patientId,
			dialogId: 'create-therapy-dialog',
			onSave: therapyId => {
				showSuccessMessageAction(this.t('create_therapy_success_msg'));

				// Reload therapy select options and select the newly created one
				if (this.therapySelectRef) {
					this.therapySelectRef.reload().then(() => this.handleValueChange('therapyId', therapyId));
				}
			},
		}, {
			id: 'create-therapy-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 1000
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open the dialog to edit an existing therapy for the selected patient
	 */
	async openEditTherapyDialog() {
		const {patientId, openDialogAction, showSuccessMessageAction, fetchPatientTherapyAction} = this.props;
		const selectedTherapyId = this.getValue('therapyId');
		
		if (!!selectedTherapyId) {
			const loading = this.showLoading('#create-appointment-dialog .dialog-content');
			/** @type {PatientTherapyDataObject} */
			const therapy = await this.executeAbortableAction(fetchPatientTherapyAction, selectedTherapyId);
			hideLoading(loading);

			if (isset(therapy)) {
				const dialogGUIID = openDialogAction('', TherapyDialog, {
					isNew: false,
					data: therapy,
					patientId,
					dialogId: 'edit-therapy-dialog',
					onSave: therapyId => {
						showSuccessMessageAction(this.t('save_therapy_success_msg'));

						// Reload therapy select options and select the newly created one
						if (this.therapySelectRef) {
							this.therapySelectRef.reload().then(() => this.handleValueChange('therapyId', therapyId));
						}
					},
				}, {
					id: 'edit-therapy-dialog',
					className: 'bordered-title',
					closeOnEscape: true,
					closeOnClickOutside: false,
					hideCloseBtn: true,
					maxWidth: 1000
				});
				this.setOption(
					'dialogsToCloseOnUnmount',
					trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
				);
			}
		}
	}

	/**
	 * Delete dialog method
	 */
	delete() {
		const {deleteAppointmentAction, openDialogAction, closeDialogAction} = this.props;
		
		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('confirm_delete', 'Appointments'),
			supportHtml: true,
			onYes: () => {
				// Close confirm dialog
				closeDialogAction(dialogGUIID);
				
				this.executeAbortableAction(deleteAppointmentAction, this.getValue('id'))
					.then(res => {
						// If delete was successful
						if (isset(res)) {
							const {addSuccessMessageAction} = this.props;

							addSuccessMessageAction(this.t('delete_success_msg', 'Appointments'));
							
							// Close appointment dialog
							this.close();
							// Trigger component's onDelete event
							executeComponentCallback(this.props.onDelete);
						}
						return res;
					});
			},
		}, {
			id: 'appointment-delete-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 600
		});
		this.setOption(
			'dialogsToCloseOnUnmount', 
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render dialog action buttons used by data dialogs
	 * @note Every dialog that is used to manipulate some data should use these action buttons on the bottom of the main
	 * render method. Standard buttons include create, save, delete, restore and close and they will be rendered
	 * depending on dialog type and available events (isNew, isRestore, onDelete, ...).
	 *
	 * @param {string|null} [createLabel] - Label used for create button. Default value will be loaded from translation file.
	 * @param {string|null} [createIcon] - Optional icon used for create button.
	 * @param {string|null} [deleteLabel] - Label used for delete button. Default value will be loaded from translation file.
	 * @param {string|null} [deleteIcon] - Optional icon used for delete button.
	 * @param {string|null} [saveLabel] - Label used for save button. Default value will be loaded from translation file.
	 * @param {string|null} [saveIcon] - Optional icon used for save button.
	 * @param {string|null} [closeLabel] - Label used for close button. Default value will be loaded from translation file.
	 * @param {string|null} [closeIcon] - Optional icon used for close button.
	 * @param {string|null} [restoreLabel] - Label used for restore button. Default value will be loaded from translation
	 * file.
	 * @param {string|null} [restoreIcon] - Optional icon used for restore button.
	 * @param {boolean} [hideClose=false] - If true, close button will not be rendered. This is useful for confirm
	 * dialogs.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderDataActionButtons({
		createLabel = undefined, createIcon = undefined, deleteLabel = undefined, deleteIcon = undefined,
		saveLabel = undefined, saveIcon = undefined, closeLabel = undefined, closeIcon = undefined,
		restoreLabel = undefined, restoreIcon = undefined
	} = {}, hideClose = false) {
		const {isNew} = this.props;

		let buttons = [];

		// Close/cancel button
		if (isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		// Delete button
		if (!isNew) buttons.push({
			style: BUTTON_STYLE.ERROR,
			label: (deleteLabel ? deleteLabel : this.t('Delete', 'general')),
			icon: (isset(deleteIcon) && deleteIcon !== null ? deleteIcon : icon_font_delete_symbol),
			onClick: this.delete,
		});

		// Save buttons (create, restore and save)
		if (isNew) buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (createLabel ? createLabel : this.t('Create', 'general')),
			icon: (typeof createIcon === 'undefined' ? icon_font_create_symbol : createIcon),
			onClick: this.save,
			autofocus: true,
		});
		else buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (saveLabel ? saveLabel : this.t('Save', 'general')),
			icon: (isset(saveIcon) && saveIcon !== null ? saveIcon : icon_font_save_symbol),
			onClick: this.save,
			autofocus: true,
		});
		
		// Log visit button
		if (!isNew) buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: this.t('create_visit_btn'),
			icon: 'checked',
			iconProps: {symbolPrefix: 'icofont-'},
			onClick: this.openCreateVisitDialog,
		});

		// Close/cancel button
		if (!isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		return this.renderActionButtons(buttons);
	}

	render() {
		const {isNew, title, patientId, patientInfo, therapySelect, therapyId, showHelp} = this.props;

		/** @type {AppointmentCreateDataObject|AppointmentUpdateDataObject} */
		const data = this.getData();

		return this.renderDialog(
			this.renderTitle(
				isNew ? 
					this.t(!!title ? title : !!therapyId ? 'title_create_therapy' : 'title_create') : 
					this.t('title')
			),
			(
				<FormWrapper className="dialog-form">
					{!!patientInfo ? patientInfo : null}

					{therapySelect ?
						<FormField
							label={this.t('therapyIdLabel')}
							errorMessages={this.getValidationErrors('therapyId')}
						>
							<PatientTherapySelectInput
								className="patient-therapy-select-input separate-toolbar-right"
								simpleValue={true}
								isClearable={true}
								patientId={patientId}
								value={data?.therapyId || null}
								onChange={o => this.handleValueChange('therapyId', o)}
								toolbarButtons={[
									{
										position: ASYNC_SELECT_INPUT_TOOLBAR_POSITION.RIGHT,
										icon: icon_font_edit_symbol,
										tooltip: `${this.t('editTherapyBtn')} ...`,
										displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
										displayStyle: BUTTON_STYLE.ACTION,
										onClick: () => this.openEditTherapyDialog(),
										hide: !data?.therapyId,
									},
									{
										position: SELECT_INPUT_TOOLBAR_POSITION.RIGHT,
										label: this.t('createTherapyBtn'),
										icon: icon_font_create_symbol,
										displayStyle: BUTTON_STYLE.SUCCESS,
										displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
										onClick: () => this.openCreateTherapyDialog(),
									}
								]}
								ref={node => { this.therapySelectRef = node; }}
							/>
						</FormField>
						: null
					}
					
					<FormField
						required={true}
						label={this.t('dateLabel')}
						errorMessages={this.getValidationErrors('date')}
					>
						<DateInput
							preventTab={true}
							value={data?.date || null}
							onChange={v => this.handleValueChange('date', v)}
						/>
					</FormField>

					<FormField
						required={true}
						label={this.t('timeFromLabel')}
						errorMessages={this.getValidationErrors('timeFrom')}
					>
						<DayTimeInput
							timeConstraints={{minutes: {step: 5}}}
							value={data?.timeFrom || null}
							onChange={v => {
								let previousTimeFrom = new Date(this.getValue('timeFrom'));
								let difference = new Date(v) - previousTimeFrom;
								this.handleValueChange('timeFrom', v)
								.then(() => {
									let newTimeTo = new Date(new Date(this.getValue('timeTo')).getTime() + difference);
									this.handleValueChange('timeTo', newTimeTo).then();
								})
							}}
						/>
					</FormField>

					<FormField
						required={true}
						label={this.t('timeToLabel')}
						errorMessages={this.getValidationErrors('timeTo')}
					>
						<DayTimeInput
							timeConstraints={{minutes: {step: 5}}}
							value={data?.timeTo || null}
							onChange={v => this.handleValueChange('timeTo', v)
								.then(() => {
									if (isBefore(this.getValue('timeTo'), this.getValue('timeFrom'))) {
										this.handleValueChange('timeTo', this.getValue('timeFrom')).then();
									}
								})
							}
						/>
					</FormField>

					{isNew ?
						<FormField
							required={true}
							label={this.t('appointmentNumberLabel')}
							errorMessages={this.getValidationErrors('appointmentNumber')}
						>
							<NumberInput
								intOnly={true}
								value={data?.appointmentNumber || null}
								useAppLocaleCode={NUMBER_INPUT_USE_APP_LOCALE.NONE}
								onChange={v => this.handleValueChange('appointmentNumber', v)}
							/>
						</FormField>
						: null
					}
					
					<FormField
						required={true}
						label={this.t('officeLocationIdLabel')}
						errorMessages={this.getValidationErrors('officeLocationId')}
					>
						<OfficeLocationSelectInput
							isClearable={false}
							selectOnlyOptionOnLoad={isNew}
							value={data?.officeLocationId || null}
							onChange={v => this.handleValueChange('officeLocationId', v)}
						/>
					</FormField>

					<FormField
						label={this.t('therapistIdLabel')}
						errorMessages={this.getValidationErrors('therapistId')}
					>
						<TherapistSelectInput
							isClearable={true}
							selectOnlyOptionOnLoad={isNew}
							value={data?.therapistId || null}
							onChange={v => this.handleValueChange('therapistId', v)}
						/>
					</FormField>

					{isNew ?
						<FormField
							label={this.t('daysOfWeekLabel')}
							errorMessages={this.getValidationErrors('daysOfWeek')}
							inputClassName="tags"
						>
							{DAYS_OF_WEEK.map((d, idx) =>
								<CheckboxInput
									key={d}
									className="tag outline control no-select"
									checkedColor="var(--action-color-main)"
									label={this.translatePath(`constants.days.${idx + 1}.short`)}
									value={d}
									checked={getArray(data, 'daysOfWeek').includes(d)}
									onChange={() => {
										let updatedValue = clone(getArray(data, 'daysOfWeek'));
										if (getArray(data, 'daysOfWeek').includes(d)) {
											updatedValue = without(getArray(data, 'daysOfWeek'), d);
										} else {
											updatedValue.push(d);
										}
										updatedValue = updatedValue.sort();
										this.setValue('daysOfWeek', updatedValue).then();
									}}
								/>
							)}
						</FormField>
						: null
					}

					<FormField
						className="multiline-form-field"
						label={this.t('noteLabel')}
						errorMessages={this.getValidationErrors('note')}
					>
						<TextareaInput
							rows={3}
							name="note"
							value={data?.note || ''}
							onChange={this.handleInputChange}
						/>
					</FormField>
					
					{showHelp ?
						<Accordion 
							cardStyle={true}
							title={<Label icon={icon_font_help_circle_symbol} content={this.t('Help', 'general')} />}
							onAfterOpen={elem => {
								scrollToElement(
									elem, false, 0, '#update-regular-appointment-dialog .dialog-content-component > .content'
								);
								document.activeElement.blur();
							}}
						>
							<Label content={this.t('help')} supportHtml={true} />
						</Accordion>
						: null
					}
				</FormWrapper>
			),
			{
				createLabel: this.t('create_btn'),
				createIcon: 'check'
			}
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AppointmentDialog.propTypes = {
	// Flag that specifies if this dialog is for creating a new item
	// @note This prop can be dynamically changed in the popup component.
	isNew: PropTypes.bool,
	// Dialog title ot override the default one
	title: PropTypes.string,
	// Appointment data if 'isNew' is false
	// @type {AppointmentUpdateDataObject}
	data: PropTypes.object,
	// Start date and time to use when creating a new appointment ('isNew' is true)
	// @note If not specified, default start date will be used (@see 'calendar_min_time_hours' app config option).
	startDate: PropTypes.object,
	// End date and time to use when creating a new appointment ('isNew' is true)
	// @note If not specified, default start date will be used (@see 'calendar_min_time_hours' app config option).
	endDate: PropTypes.object,
	// ID of the patient
	patientId: PropTypes.string.isRequired,
	// Patient info to be rendered at the top of the dialog
	// @note If empty, no patient info will be rendered.
	patientInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if therapy should be selectable inside the dialog
	therapySelect: PropTypes.bool,
	// ID of the therapy
	therapyId: PropTypes.string,
	// Flag that specifies if patient record will be loaded after appointment has been saved (created/updated)
	loadPatientRecordOnSave: PropTypes.bool,
	// Flag that specifies if help section will be rendered
	showHelp: PropTypes.bool,

	// Event triggered if appointment was saved successfully and dialog has already been closed
	// @param {string} appointmentId, 
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
	// Event triggered after appointment has been successfully deleted
	// @note Dialog will be closed automatically.
	// no params
	onDelete: PropTypes.func,
};

/**
 * Define component default values for own props
 */
AppointmentDialog.defaultProps = {
	loadPatientRecordOnSave: true,
};

export default connect(null, allActions)(AppointmentDialog);