import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {ExpertReportTypesItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ExpertReportTypesItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new ExpertReportTypesItemDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
		getString(result, 'defaultText'),
		getString(result, 'templateFileId'),
		getString(result, 'templateFileName')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {ExpertReportTypesItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		id: inputData.id,
		name: inputData.name,
		defaultText: inputData.content,
		templateFileId: inputData.templateFileId,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}