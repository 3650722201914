import React, {useState, useEffect} from "react";
import {i18nEffect, translate, getTranslation} from "Core/i18n";
import {
	app_id,
	locale_cookie_group,
	sidebar_menu_groups_statuses_storage_field_name,
	sidebar_menu_shrank_cookie_group,
	skin_mode_cookie_group
} from 'Config/app';

/**
 * Cookie policy for en-US locale
 * @note This will automatically be used in CookiePolicy component (index.js) if en-US is the current locale.
 * There is no need to use this component directly.
 *
 * @param {any} props - Any prop that will be added to the DIV element.
 * @return {JSX.Element}
 * @constructor
 */
function CookiePolicyEnUs(props) {
	const i18nEffectHook = i18nEffect.bind(null, useState(getTranslation())[1]);
	useEffect(() => { i18nEffectHook(); }, [i18nEffectHook]);
	
	return (
		<div {...props}>
			<h2>About cookies</h2>
			<p>...</p>
			
			<h2>Cookie specification</h2>
			<div className="data-table-component standard">
				<table>
					<thead>
						<tr>
							<th className="column"><div className="content">COOKIE CATEGORY</div></th>
							<th className="column" width="1"><div className="content">COOKIE NAME</div></th>
							<th className="column"><div className="content">COOKIE DESCRIPTION</div></th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_pauseRequests</div></td>
							<td><div className="content">A cookie used to pause system IO requests</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_authToken</div></td>
							<td><div className="content">A cookie used to authorize a logged in user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_authTokenData</div></td>
							<td><div className="content">A cookie containing the system data of the logged in user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_refreshToken</div></td>
							<td><div className="content">A cookie used to authorize a logged in user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_refreshingToken</div></td>
							<td><div className="content">A cookie used to authorize a logged in user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_acl</div></td>
							<td><div className="content">A cookie containing current users access control list (ACL)</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_currentUser</div></td>
							<td><div className="content">A cookie containing current users system data (username, user type, etc.)</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_userActivity</div></td>
							<td><div className="content">A cookie containing current users system activity data.</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_cookie_consent_settings</div></td>
							<td><div className="content">A cookie that stores information about which cookies are accepted by the user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${locale_cookie_group}`)}</div></td>
							<td><div className="content">{app_id}_locale</div></td>
							<td><div className="content">A cookie containing the system locale code used to determine the language that will be used</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${sidebar_menu_shrank_cookie_group}`)}</div></td>
							<td><div className="content">sidebar_menu_shrank</div></td>
							<td><div className="content">A cookie used to determines the size of the main menu sidebar</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:necessary`)}</div></td>
							<td><div className="content">{sidebar_menu_groups_statuses_storage_field_name}</div></td>
							<td><div className="content">A cookie used to determines which menu items should be expanded</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${skin_mode_cookie_group}`)}</div></td>
							<td><div className="content">skin_mode</div></td>
							<td><div className="content">A cookie used to determines the skin of the app</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${skin_mode_cookie_group}`)}</div></td>
							<td><div className="content">use_system_skin_mode</div></td>
							<td><div className="content">A cookie used to determines the skin of the app</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:functional`)}</div></td>
							<td><div className="content">emoji-mart.frequently</div></td>
							<td><div className="content">A cookie used to save frequent used emojis so they can be displayed when choosing an emoji to insert</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:functional`)}</div></td>
							<td><div className="content">emoji-mart.last</div></td>
							<td><div className="content">A cookie used to save the last used emoji</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:functional`)}</div></td>
							<td><div className="content">calendar_preferences</div></td>
							<td><div className="content">A cookie used to save home page calendar preferences</div></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default CookiePolicyEnUs;