/** @typedef {string} ExpertReportTag */
export const EXPERT_REPORT_TAG_IME_PACIJENTA = '%%IME PACIJENTA%%';
export const EXPERT_REPORT_TAG_PATIENT_FIRST_NAME = '%%PATIENT FIRST NAME%%';
export const EXPERT_REPORT_TAG_PREZIME_PACIJENTA = '%%PREZIME PACIJENTA%%';
export const EXPERT_REPORT_TAG_PATIENT_LAST_NAME = '%%PATIENT LAST NAME%%';
export const EXPERT_REPORT_TAG_RODITELJ_PACIJENTA = '%%RODITELJ PACIJENTA%%';
export const EXPERT_REPORT_TAG_PATIENT_PARENT = '%%PATIENT PARENT%%';
export const EXPERT_REPORT_TAG_EMAIL_PACIJENTA = '%%EMAIL PACIJENTA%%';
export const EXPERT_REPORT_TAG_PATIENT_EMAIL = '%%PATIENT EMAIL%%';
export const EXPERT_REPORT_TAG_TELEFON_PACIJENTA = '%%TELEFON PACIJENTA%%';
export const EXPERT_REPORT_TAG_PATIENT_PHONE = '%%PATIENT PHONE%%';
export const EXPERT_REPORT_TAG_DATUM_ROĐENJA_PACIJENTA = '%%DATUM ROĐENJA PACIJENTA%%';
export const EXPERT_REPORT_TAG_DATUM_RODJENJA_PACIJENTA = '%%DATUM RODJENJA PACIJENTA%%';
export const EXPERT_REPORT_TAG_PATIENT_DATE_OF_BIRTH = '%%PATIENT DATE OF BIRTH%%';
export const EXPERT_REPORT_TAG_AUTOR_IZVEŠTAJA = '%%AUTOR IZVEŠTAJA%%';
export const EXPERT_REPORT_TAG_REPORT_CREATOR = '%%REPORT CREATOR%%';
export const EXPERT_REPORT_TAG_ŠIFRA_DIJAGNOZE = '%%ŠIFRA DIJAGNOZE%%';
export const EXPERT_REPORT_TAG_DIAGNOSIS_CODE = '%%DIAGNOSIS CODE%%';
export const EXPERT_REPORT_TAG_MKB10_DIJAGNOZA = '%%MKB10 DIJAGNOZA%%';
export const EXPERT_REPORT_TAG_MKB10_DIAGNOSIS = '%%MKB10 DIAGNOSIS%%';
export const EXPERT_REPORT_TAG_DIJAGNOZA = '%%DIJAGNOZA%%';
export const EXPERT_REPORT_TAG_DIAGNOSIS = '%%DIAGNOSIS%%';
export const EXPERT_REPORT_TAG_OPŠTA_ANAMNEZA = '%%OPŠTA ANAMNEZA%%';
export const EXPERT_REPORT_TAG_GENERAL_ANAMNESIS = '%%GENERAL ANAMNESIS%%';
export const EXPERT_REPORT_TAG_SIMPTOMI_ANAMNEZA = '%%SIMPTOMI/ANAMNEZA%%';
export const EXPERT_REPORT_TAG_SYMPTOMS_ANAMNESYS = '%%SYMPTOMS/ANAMNESYS%%';
export const EXPERT_REPORT_TAG_PREPISANE_TERAPIJE = '%%PREPISANE TERAPIJE%%';
export const EXPERT_REPORT_TAG_PRESCRIBED_THERAPIES = '%%PRESCRIBED THERAPIES%%';
export const EXPERT_REPORT_TAG_NAZIV_IZVEŠTAJA = '%%NAZIV IZVEŠTAJA%%';
export const EXPERT_REPORT_TAG_REPORT_TITLE = '%%REPORT TITLE%%';
export const EXPERT_REPORT_TAG_DATUM_IZVEŠTAJA = '%%DATUM IZVEŠTAJA%%';
export const EXPERT_REPORT_TAG_REPORT_DATE = '%%REPORT DATE%%';
export const EXPERT_REPORT_TAG_IZVEŠTAJ = '%%IZVEŠTAJ%%';
export const EXPERT_REPORT_TAG_REPORT = '%%REPORT%%';
/** @enum {ExpertReportTag} */
export const EXPERT_REPORT_TAG = {
	IME_PACIJENTA: EXPERT_REPORT_TAG_IME_PACIJENTA,
	PATIENT_FIRST_NAME: EXPERT_REPORT_TAG_PATIENT_FIRST_NAME,
	PREZIME_PACIJENTA: EXPERT_REPORT_TAG_PREZIME_PACIJENTA,
	PATIENT_LAST_NAME: EXPERT_REPORT_TAG_PATIENT_LAST_NAME,
	RODITELJ_PACIJENTA: EXPERT_REPORT_TAG_RODITELJ_PACIJENTA,
	PATIENT_PARENT: EXPERT_REPORT_TAG_PATIENT_PARENT,
	EMAIL_PACIJENTA: EXPERT_REPORT_TAG_EMAIL_PACIJENTA,
	PATIENT_EMAIL: EXPERT_REPORT_TAG_PATIENT_EMAIL,
	TELEFON_PACIJENTA: EXPERT_REPORT_TAG_TELEFON_PACIJENTA,
	PATIENT_PHONE: EXPERT_REPORT_TAG_PATIENT_PHONE,
	DATUM_ROĐENJA_PACIJENTA: EXPERT_REPORT_TAG_DATUM_ROĐENJA_PACIJENTA,
	DATUM_RODJENJA_PACIJENTA: EXPERT_REPORT_TAG_DATUM_RODJENJA_PACIJENTA,
	PATIENT_DATE_OF_BIRTH: EXPERT_REPORT_TAG_PATIENT_DATE_OF_BIRTH,
	AUTOR_IZVEŠTAJA: EXPERT_REPORT_TAG_AUTOR_IZVEŠTAJA,
	REPORT_CREATOR: EXPERT_REPORT_TAG_REPORT_CREATOR,
	ŠIFRA_DIJAGNOZE: EXPERT_REPORT_TAG_ŠIFRA_DIJAGNOZE,
	DIAGNOSIS_CODE: EXPERT_REPORT_TAG_DIAGNOSIS_CODE,
	MKB10_DIJAGNOZA: EXPERT_REPORT_TAG_MKB10_DIJAGNOZA,
	MKB10_DIAGNOSIS: EXPERT_REPORT_TAG_MKB10_DIAGNOSIS,
	DIJAGNOZA: EXPERT_REPORT_TAG_DIJAGNOZA,
	DIAGNOSIS: EXPERT_REPORT_TAG_DIAGNOSIS,
	OPŠTA_ANAMNEZA: EXPERT_REPORT_TAG_OPŠTA_ANAMNEZA,
	GENERAL_ANAMNESIS: EXPERT_REPORT_TAG_GENERAL_ANAMNESIS,
	SIMPTOMI_ANAMNEZA: EXPERT_REPORT_TAG_SIMPTOMI_ANAMNEZA,
	SYMPTOMS_ANAMNESYS: EXPERT_REPORT_TAG_SYMPTOMS_ANAMNESYS,
	PREPISANE_TERAPIJE: EXPERT_REPORT_TAG_PREPISANE_TERAPIJE,
	PRESCRIBED_THERAPIES: EXPERT_REPORT_TAG_PRESCRIBED_THERAPIES,
	NAZIV_IZVEŠTAJA: EXPERT_REPORT_TAG_NAZIV_IZVEŠTAJA,
	REPORT_TITLE: EXPERT_REPORT_TAG_REPORT_TITLE,
	DATUM_IZVEŠTAJA: EXPERT_REPORT_TAG_DATUM_IZVEŠTAJA,
	REPORT_DATE: EXPERT_REPORT_TAG_REPORT_DATE,
	IZVEŠTAJ: EXPERT_REPORT_TAG_IZVEŠTAJ,
	REPORT: EXPERT_REPORT_TAG_REPORT,
};
/** @type {ExpertReportTag[]} */
export const EXPERT_REPORT_TAGS = [
	EXPERT_REPORT_TAG_IME_PACIJENTA,
	EXPERT_REPORT_TAG_PATIENT_FIRST_NAME,
	EXPERT_REPORT_TAG_PREZIME_PACIJENTA,
	EXPERT_REPORT_TAG_PATIENT_LAST_NAME,
	EXPERT_REPORT_TAG_RODITELJ_PACIJENTA,
	EXPERT_REPORT_TAG_PATIENT_PARENT,
	EXPERT_REPORT_TAG_EMAIL_PACIJENTA,
	EXPERT_REPORT_TAG_PATIENT_EMAIL,
	EXPERT_REPORT_TAG_TELEFON_PACIJENTA,
	EXPERT_REPORT_TAG_PATIENT_PHONE,
	EXPERT_REPORT_TAG_DATUM_ROĐENJA_PACIJENTA,
	EXPERT_REPORT_TAG_DATUM_RODJENJA_PACIJENTA,
	EXPERT_REPORT_TAG_PATIENT_DATE_OF_BIRTH,
	EXPERT_REPORT_TAG_AUTOR_IZVEŠTAJA,
	EXPERT_REPORT_TAG_REPORT_CREATOR,
	EXPERT_REPORT_TAG_ŠIFRA_DIJAGNOZE,
	EXPERT_REPORT_TAG_DIAGNOSIS_CODE,
	EXPERT_REPORT_TAG_MKB10_DIJAGNOZA,
	EXPERT_REPORT_TAG_MKB10_DIAGNOSIS,
	EXPERT_REPORT_TAG_DIJAGNOZA,
	EXPERT_REPORT_TAG_DIAGNOSIS,
	EXPERT_REPORT_TAG_OPŠTA_ANAMNEZA,
	EXPERT_REPORT_TAG_GENERAL_ANAMNESIS,
	EXPERT_REPORT_TAG_SIMPTOMI_ANAMNEZA,
	EXPERT_REPORT_TAG_SYMPTOMS_ANAMNESYS,
	EXPERT_REPORT_TAG_PREPISANE_TERAPIJE,
	EXPERT_REPORT_TAG_PRESCRIBED_THERAPIES,
	EXPERT_REPORT_TAG_NAZIV_IZVEŠTAJA,
	EXPERT_REPORT_TAG_REPORT_TITLE,
	EXPERT_REPORT_TAG_DATUM_IZVEŠTAJA,
	EXPERT_REPORT_TAG_REPORT_DATE,
	EXPERT_REPORT_TAG_IZVEŠTAJ,
	EXPERT_REPORT_TAG_REPORT,
];

export const EXPERT_REPORT_TAGS_BY_LANG = {
	"en-US": [
		EXPERT_REPORT_TAG_PATIENT_FIRST_NAME,
		EXPERT_REPORT_TAG_PATIENT_LAST_NAME,
		EXPERT_REPORT_TAG_PATIENT_PARENT,
		EXPERT_REPORT_TAG_PATIENT_EMAIL,
		EXPERT_REPORT_TAG_PATIENT_PHONE,
		EXPERT_REPORT_TAG_PATIENT_DATE_OF_BIRTH,
		EXPERT_REPORT_TAG_REPORT_CREATOR,
		EXPERT_REPORT_TAG_DIAGNOSIS_CODE,
		EXPERT_REPORT_TAG_MKB10_DIAGNOSIS,
		EXPERT_REPORT_TAG_DIAGNOSIS,
		EXPERT_REPORT_TAG_GENERAL_ANAMNESIS,
		EXPERT_REPORT_TAG_SYMPTOMS_ANAMNESYS,
		EXPERT_REPORT_TAG_PRESCRIBED_THERAPIES,
		EXPERT_REPORT_TAG_REPORT_TITLE,
		EXPERT_REPORT_TAG_REPORT_DATE,
		EXPERT_REPORT_TAG_REPORT,
	],
	"sr-Latn-RS": [
		EXPERT_REPORT_TAG_IME_PACIJENTA,
		EXPERT_REPORT_TAG_PREZIME_PACIJENTA,
		EXPERT_REPORT_TAG_RODITELJ_PACIJENTA,
		EXPERT_REPORT_TAG_EMAIL_PACIJENTA,
		EXPERT_REPORT_TAG_TELEFON_PACIJENTA,
		EXPERT_REPORT_TAG_DATUM_ROĐENJA_PACIJENTA,
		EXPERT_REPORT_TAG_DATUM_RODJENJA_PACIJENTA,
		EXPERT_REPORT_TAG_AUTOR_IZVEŠTAJA,
		EXPERT_REPORT_TAG_ŠIFRA_DIJAGNOZE,
		EXPERT_REPORT_TAG_MKB10_DIJAGNOZA,
		EXPERT_REPORT_TAG_DIJAGNOZA,
		EXPERT_REPORT_TAG_OPŠTA_ANAMNEZA,
		EXPERT_REPORT_TAG_SIMPTOMI_ANAMNEZA,
		EXPERT_REPORT_TAG_PREPISANE_TERAPIJE,
		EXPERT_REPORT_TAG_NAZIV_IZVEŠTAJA,
		EXPERT_REPORT_TAG_DATUM_IZVEŠTAJA,
		EXPERT_REPORT_TAG_IZVEŠTAJ,
	],
	"sr-Cyrl-RS": [
		EXPERT_REPORT_TAG_IME_PACIJENTA,
		EXPERT_REPORT_TAG_PREZIME_PACIJENTA,
		EXPERT_REPORT_TAG_RODITELJ_PACIJENTA,
		EXPERT_REPORT_TAG_EMAIL_PACIJENTA,
		EXPERT_REPORT_TAG_TELEFON_PACIJENTA,
		EXPERT_REPORT_TAG_DATUM_ROĐENJA_PACIJENTA,
		EXPERT_REPORT_TAG_DATUM_RODJENJA_PACIJENTA,
		EXPERT_REPORT_TAG_AUTOR_IZVEŠTAJA,
		EXPERT_REPORT_TAG_ŠIFRA_DIJAGNOZE,
		EXPERT_REPORT_TAG_MKB10_DIJAGNOZA,
		EXPERT_REPORT_TAG_DIJAGNOZA,
		EXPERT_REPORT_TAG_OPŠTA_ANAMNEZA,
		EXPERT_REPORT_TAG_SIMPTOMI_ANAMNEZA,
		EXPERT_REPORT_TAG_PREPISANE_TERAPIJE,
		EXPERT_REPORT_TAG_NAZIV_IZVEŠTAJA,
		EXPERT_REPORT_TAG_DATUM_IZVEŠTAJA,
		EXPERT_REPORT_TAG_IZVEŠTAJ,
	]
};