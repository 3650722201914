import {hideLoadingFunction, showDialogLoading} from 'Core/helpers/loading';
import {ioJsonAction, ioJsonFetchAction, ioJsonFetchItemAction} from 'Core/store/actions/io';
import {getArray, isset} from 'Core/helpers/data';
import * as expertReportDataMap from "DataMap/expertReport";
import {get} from 'lodash';
import {isSuccessful} from "Core/helpers/io";
import * as expertReportTypeDataMap from "Pages/apps/default/expertReportTypes/dataMap/item";

/**
 * Fetch an expert report
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Expert report ID.
 * @return {function(*): Promise<ExpertReportDataObject|undefined>}
 */
export const fetchExpertReportAction = (abortCallback, id) => dispatch => {
	const loading = showDialogLoading('edit-expert-report-dialog');
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/expert-report/fetch-by-id',
		id,
		undefined,
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? expertReportDataMap.input(get(res, 'data')) : undefined);
}

/**
 * Create an expert report
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {ExpertReportDataObject} report
 * @return {function(*): Promise<ExpertReportDataObject|undefined>}
 */
export const createExpertReportAction = (abortCallback, report) => dispatch => {
	const loading = showDialogLoading('create-expert-report-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/expert-report/create',
		{
			id: null,
			data: expertReportDataMap.output(report),
			requestSavedData: true,
		},
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? expertReportDataMap.input(get(res, 'data')) : undefined);
}

/**
 * Update an expert report
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id
 * @param {ExpertReportDataObject} report
 * @return {function(*): Promise<ExpertReportDataObject|undefined>}
 */
export const updateExpertReportAction = (abortCallback, id, report) => dispatch => {
	const loading = showDialogLoading('edit-expert-report-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/expert-report/update-by-id',
		{
			id,
			data: expertReportDataMap.output(report),
			requestSavedData: true,
		},
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? expertReportDataMap.input(get(res, 'data')) : undefined);
}

/**
 * Fetch the list of expert report types
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<ExpertReportTypesItemDataObject[]|undefined>}
 */
export const fetchExpertReportTypesAction = abortCallback => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/expert-report-type/search',
		'',
		null,
		null,
		1,
		9999,
		null,
	)(dispatch)
		.then(res => isSuccessful(res) ? getArray(res, 'data').map(i => expertReportTypeDataMap.input(i)) : undefined);
}