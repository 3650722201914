import "./index.css";

import React from "react";
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {ExpertReportDataObject} from "DataObjects/expertReport";
import DataValueValidation, {ValidationRuleObject} from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {getArray, getString, isset, trimArray} from 'Core/helpers/data';
import * as actions from "./actions";
import {getGlobalActions} from 'Core/helpers/redux';
import DateInput from 'Core/components/input/DateInput';
import TextInput from "Core/components/input/TextInput";
import {loadPatientTherapyAction} from "Components/advanced/PatientRecord/components/Therapy/actions";
import {loadPatientRecordAction} from "Components/advanced/PatientRecord/actions";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import Label from "Core/components/display/Label";
import {showMessage} from "Core/helpers/message";
import {MESSAGE_STYLE} from "Core/components/global/Message/const";
import {EXPERT_REPORT_TAGS_BY_LANG} from "Pages/apps/default/expertReportTypes/const";
import {routerPath as expertReportTypesPageRouterPath} from "Pages/apps/default/expertReportTypes";
import {getAppLocaleCode} from "Core/helpers/locale";
import SelectInput from "Core/components/input/SelectInput";
import {cloneDeep} from "lodash";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import {SELECT_INPUT_TOOLBAR_POSITION} from "Core/components/input/SelectInput/const";
import {redirectToPath} from "Core/helpers/url";
import {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import ExpertReportInput from "Components/input/ExpertReportInput";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({...actions, loadPatientTherapyAction, loadPatientRecordAction});

class ExpertReportDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data:
				props.isNew ?
					new ExpertReportDataObject(
						null,
						props.patientId,
						props.therapyId,
						new Date(),
					)
					:
					null,

			/**
			 * List of all expert report types
			 * @type {?ExpertReportTypesItemDataObject|undefined}
			 */
			types: null,
		}, {
			translationPath: 'ExpertReportDialog',
			domPrefix: 'expert-report-dialog',
			enableLoadOnDataPropChange: true,
			disableLoad: true,
		});

		// Data change handling methods 
		this.handleTypeChange = this.handleTypeChange.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount(override = false) {
		await super.asyncComponentDidMount(override);

		const {isNew, id, fetchExpertReportAction, fetchExpertReportTypesAction} = this.props;

		// Load expert report types
		this.executeAbortableActionMount(fetchExpertReportTypesAction)
			.then(types => this.setState({types}));
		

		// Load data if on edit dialog and ID is specified
		if (!isNew && !!id) {
			await this.executeAbortableActionMount(fetchExpertReportAction, id)
				// Load expert report into local component's state
				.then(data => this.setData(data).then(() => this.originalData = cloneDeep(data)));
		} else {
			this.originalData = this.getData();
		}
	}
	
	/**
	 * Handle expert report type change 
	 * @param {?string} selectedOption - Selected expert report type ID.
	 * @param {ExpertReportTypesItemDataObject} selectedValue - Selected expert report type object.
	 */
	handleTypeChange(selectedOption, selectedValue) {
		const {openDialogAction, closeDialogAction} = this.props;
		let originalContent = getString(this.getOriginalData(), 'content');
		if (originalContent === '<p><br></p>') originalContent = '';
		let content = getString(this.getValue('content'));
		if (content === '<p><br></p>') content = '';
		const selectedContent = getString(selectedValue, 'content');
		
		// If selection is cleared, do not update report content
		if (selectedValue === null) {
			this.handleValueChange('typeId', selectedOption).then();
			return;
		}
		// Update report content
		if (content !== originalContent || content !== selectedContent) {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('typeChangeConfirmationMessage'),
				supportHtml: true,
				onYes: () => {
					this.handleValueChange('typeId', selectedOption)
						.then(() => this.setValue('content', getString(selectedValue, 'content')))
						.then(() => this.originalData = this.getData());
					closeDialogAction(dialogGUIID);
				},
			}, {
				id: 'confirm-expert-report-type-change-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 500
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		} else {
			this.handleValueChange('typeId', selectedOption)
				.then(() => this.setValue('content', getString(selectedValue, 'content')));
		}
	}

	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		/** @type {ExpertReportDataObject} */
		const dataToValidate = this.getData();

		dataValidation.addRule('date', 'required', 'date');
		dataValidation.addRule('content', new ValidationRuleObject('custom', {validate: value => {
			let passed;

			if (typeof value === 'string') passed = !!value.replace(/(<([^>]+)>)/ig, "").length;
			else passed = (typeof value !== 'undefined' && value !== null);
				
			return passed ? {passed} : {passed: false, error: 'required'};
		}}));

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	async save() {
		const {
			isNew, patientId, therapyId, dialogGUIID, createExpertReportAction, updateExpertReportAction, 
			addSuccessMessageAction, loadPatientRecordAction, loadPatientTherapyAction
		} = this.props;
		
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const expertReport = (
				isNew ?
					await this.executeAbortableAction(createExpertReportAction, data) :
					await this.executeAbortableAction(updateExpertReportAction, data.id, data, false)
			);
			
			if (isset(expertReport)) {
				// Show success message
				if (isNew) addSuccessMessageAction(this.t('create_success_msg'));
				else addSuccessMessageAction(this.t('update_success_msg'));

				if (isNew) {
					// Reload patient record to update global statistics
					await this.executeAbortableAction(loadPatientRecordAction, patientId);
					// Reload patient therapy to update statistics
					if (!!therapyId && therapyId !== '*') {
						await this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section');
					}
				}

				this.close();

				// Trigger component's onSave event if visit was saved successfully
				executeComponentCallback(this.props.onSave, expertReport, dialogGUIID);
			}
		}
	}

	render() {
		const {isNew, therapyId} = this.props;
		const {types} = this.state;

		/** @type {ExpertReportDataObject} */
		const data = this.getData();
		const tags = getArray(EXPERT_REPORT_TAGS_BY_LANG, getAppLocaleCode());

		return this.renderDialog(
			this.renderTitle(isNew ? this.t(!!therapyId ? 'title_create_therapy' : 'title_create') : this.t('title')),
			(
				<FormWrapper className={`dialog-form ${this.getOption('domPrefix')}`}>
					<div className="date-and-title">
						<FormField
							required={true}
							className="date-field"
							label={this.t('dateLabel')}
							labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
							errorMessages={this.getValidationErrors('date')}
						>
							<DateInput
								preventTab={true}
								value={data?.date || null}
								onChange={v => this.handleValueChange('date', v)}
							/>
						</FormField>

						<FormField
							className="title-field"
							inputClassName="full"
							label={this.t('titleLabel')}
							labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
							errorMessages={this.getValidationErrors('title')}
						>
							<TextInput
								name="title"
								value={data?.title}
								onChange={this.handleInputChange}
							/>
						</FormField>
					</div>

					<FormField
						label={this.t('typeLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						inputClassName="full"
						errorMessages={this.getValidationErrors('type')}
					>
						<SelectInput
							primaryKey="id"
							isClearable={true}
							options={getArray(types)}
							isLoading={types === null}
							getOptionValue={o => o.id}
							getOptionLabel={o => (
								<div className="expert-report-dialog-type-option">
									<Label element="span" elementProps={{className: 'bold'}} content={o.name} />
									{!!o.templateFileName ? <Label element="small" content={` - ${o.templateFileName}`} />:null}
								</div>
							)}
							value={this.getValue('typeId')}
							onChange={this.handleTypeChange}
							toolbarButtons={[
								{
									className: 'expert-report-type-link',
									position: SELECT_INPUT_TOOLBAR_POSITION.LEFT,
									icon: 'external-link',
									iconProps: {symbolPrefix: 'icofont-'},
									tooltip: this.t('typeLinkTooltip'),
									displayType: BUTTON_DISPLAY_TYPE.NONE,
									displayStyle: BUTTON_STYLE.SUBTLE,
									onClick: () => redirectToPath(
										`${expertReportTypesPageRouterPath}/item/${this.getValue('typeId')}`, 
										true
									),
									hide: !this.getValue('typeId'),
								}
							]}
						/>
					</FormField>
					
					<FormField
						required={true}
						className="multiline-form-field content-field"
						inputClassName="full"
						label={this.t('contentLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('content')}
					>
						<ExpertReportInput 
							value={data?.content || ''}
							bounds={`#${getString(this.props, 'dialogOptions.id')} .dialog-content-component`}
							onChange={v => this.handleValueChange('content', v)}
						/>
					</FormField>
					
					{tags.length > 0 ?
						<div className="notice tags-wrapper">
							<Label 
								element="h3" 
								icon="tags" 
								content={this.t('tagsTitle', 'AppsSection.DefaultApp.ExpertReportTypesPage.ItemPopup.MainTab')} 
							/>
							<Label 
								element="p" 
								content={this.t(
									'tagsDescription', 'AppsSection.DefaultApp.ExpertReportTypesPage.ItemPopup.MainTab'
								)} 
							/>
							<Label 
								element="p" 
								elementProps={{className: 'bold'}} 
								content={this.t(
									'tagsListTitle', 'AppsSection.DefaultApp.ExpertReportTypesPage.ItemPopup.MainTab'
								)} 
							/>
							<div className="tags">
								{tags.map(tag =>
									<Label
										key={tag}
										element="span"
										elementProps={{
											className: 'tag cursor-pointer',
											onClick: () => {
												navigator.clipboard.writeText(tag)
													.then(() => {
														showMessage(
															'', 
															this.t(
																'tagCopySuccess', 
																'AppsSection.DefaultApp.ExpertReportTypesPage.ItemPopup.MainTab'
															), 
															'copy', 
															MESSAGE_STYLE.SUCCESS, 
															3
														);
													})
													.catch(e => {
														console.error('Failed to copy expert report type dynamic value', e);

														if (e.name === 'NotAllowedError') {
															showMessage(
																'',
																this.t(
																	'tagCopyNotAllowed', 
																	'AppsSection.DefaultApp.ExpertReportTypesPage.ItemPopup.MainTab'
																),
																'copy',
																MESSAGE_STYLE.ERROR,
																3
															);
														} else {
															showMessage(
																'',
																this.t(
																	'tagCopyError', 
																	'AppsSection.DefaultApp.ExpertReportTypesPage.ItemPopup.MainTab'
																),
																'copy',
																MESSAGE_STYLE.ERROR,
																3
															);
														}
													});
											},
										}}
										tooltip=" "
										tooltipOptions={{
											html: (
												<>
													<strong>{this.t(tag, 'constants.expertReportTag')}</strong><br />
													(<Label 
														icon="copy" 
														content={this.t(
															'tagsClickTooltip', 
															'AppsSection.DefaultApp.ExpertReportTypesPage.ItemPopup.MainTab'
														)} 
													/>)
												</>
											),
										}}
										content={tag}
									/>
								)}
							</div>
						</div>
						: null
					}
				</FormWrapper>
			),
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ExpertReportDialog.propTypes = {
	// Flag that specifies if this dialog is for creating a new item
	// @note This prop can be dynamically changed in the popup component.
	isNew: PropTypes.bool,
	// Expert report ID used when "isNew" prop is false (edit expert report)
	id: PropTypes.string,
	// ID of the patient
	patientId: PropTypes.string.isRequired,
	// ID of the therapy
	therapyId: PropTypes.string,

	// Event triggered if expert report was saved successfully and dialog has already been closed
	// @param {ExpertReportDataObject} expert report,
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
};

export default connect(null, allActions)(ExpertReportDialog);